// File: js/panel/configuration/services.js

(function() {
    'use strict';

    var configurationModule = angular.module('configurationModule');

    configurationModule.factory('paymentService', ['$http',
        function($http) {
            return {
                verification: function() {
                    $http.post();
                    console.log('verification');
                }
            };
        }
    ]);

    configurationModule.factory('checkDomainsService', ['$resource', function($resource) {
        return $resource(':user/:site/reseller', {
            user: sessionStorage.user,
            site: '@id_site'
        }, {
            'check': {
                method: 'GET',
                isArray: false
            },
            'register': {
                method: 'POST'
            }
        });
    }]);

    configurationModule.factory('emailsService', ['$resource', function($resource) {
        return $resource(':user/:site/email/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'register': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }]);

})();
