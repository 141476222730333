// File: js/commons/controller.js

(function() {
    'use strict';

    var commonsModule = angular.module('commonsModule');

    commonsModule.controller('ConfirmCtrl', ['$scope', '$modalInstance', function($scope, $modalInstance) {

        $scope.cancel = function() {
            $modalInstance.dismiss('canceled');
        };

        $scope.accept = function() {
            $modalInstance.close('accept');
        };

        $scope.hitEnter = function(evt) {
            if (angular.equals(evt.keyCode, 13)) {
                $scope.accept();
            }
        };

    }]);
})();
