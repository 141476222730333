(function() {
    'use strict';

    /* Controllers */

    var configurationModule = angular.module('configurationModule');


    configurationModule.controller('CorreosCtrl', ['$scope', 'AuthenticationService',
        'CuestionarioSites', 'emailsService',
        function($scope, AuthenticationService, sites, emails) {

            $scope.user = sessionStorage.user_name;

            $scope.site = sites.get({
                'id': sessionStorage.site
            });

            //$scope.add_mail = 'hide';
            //$scope.limited = 'hide';

            $scope.logout = function() {
                AuthenticationService.logout();
            };

            $scope.correos = emails.list({
                    'site': sessionStorage.site
                },
                function(data) {
                    if (data.length < $scope.site.payment.package.email_accounts) {
                        $scope.add_mail = '';
                    } else {
                        $scope.limited = '';
                    }
                });

            $scope.delete_account = function(index) {
                var email = $scope.correos[index];
                if (confirm('¿Esta seguro que desea borrar ' + email.email +
                        '? Esta acción no puede deshacerse') === true) {
                    emails.delete({
                            'site': sessionStorage.site,
                            'id': email.id
                        },
                        function() {
                            $scope.correos.splice(index, 1);
                        },
                        function() {
                            alert('Ocurrió un error al borrar el correo');
                        }
                    );
                }
            };
        }
    ]);
})();
