(function() {
    'use strict';

    /* Controllers */

    var authenticationModule = angular.module('authenticationModule');

    authenticationModule.controller('LoginCtrl', ['$scope', '$location',
        'AuthenticationService', '$state',
        function($scope, $location, authentication, $state) {

            $('.page-wrap').css({
                'height': '100%'
            });

            $scope.login = function(user) {
                $scope.login_button = 'circle-loader';

                $scope.dataLoading = true;

                authentication.login(user.email, user.password, function(response) {
                    if (response.success) {
                        authentication.setCredentials(response, user.keep);

                        $state.go('inhouse');

                    } else {
                        $scope.login_button = '';
                        $scope.login_button = '';
                        var notification = new NotificationFx({
                            message: '<span class="icon error-login-icon glyphicon ' +
                                'glyphicon-remove"></span><p>Fallo en inicio de sesión</p>',
                            layout: 'bar',
                            effect: 'slidetop',
                            type: 'notice',
                            ttl: 1000,
                        });
                        notification.show();
                        $scope.dataLoading = false;
                    }
                });
            };

            $scope.register = function() {
                ga('send', 'event', 'login', 'registrarse');
                $('#ModalRegistro').modal();
            };

            $scope.$on("RegistroUser", function(event, data) {
                authentication.login(data, true);
            });
        }
    ]);

    authenticationModule.controller('LoginModalCtrl', ['$scope', '$location',
        '$rootScope', 'registerService', 'SessionService',

        function($scope, $location, $rootScope, registerService, SessionService) {

            $scope.registrar = function() {
                $scope.register_button = 'circle-loader';
                if (sessionStorage.duplicated == 'duplicated') {
                    $scope.register_button = '';
                    $scope.duplicated = 'EL CORREO YA ESTA EN USO.';
                }
                if ($scope.newuser.password.length < 6) {
                    $scope.password_match_message = 'Las contraseñas debe de ' +
                        'tener un minimo de 6 caracteres';
                    $scope.register_button = '';
                } else if ($scope.newuser.password != $scope.newuser.verify) {
                    $scope.password_match_message = 'Las contraseñas no coinciden';
                    $scope.register_button = '';
                } else {
                    $scope.password_match_message = '';
                    registerService.registro($scope.newuser, $scope);
                }
            };

            $scope.register_success = function(data) {
                sessionStorage.user_name = data.name;
                sessionStorage.user = data.hash;
                var authConfig = JSON.stringify({
                    data: {
                        hash: data.hash
                    }
                });
                SessionService.set('authConfig', authConfig);
                location.reload();
            };

            $scope.register_fail = function(data) {
                if (data == '-5') {
                    $scope.register_button = '';
                    $scope.password_match_message = 'Este usuario ya' +
                        'existe en INhouse';
                }
            };
        }
    ]);

})();
