// File: js/contenido/single/controller.js

(function() {
    'use strict';

    var contenidoModule = angular.module('contenidoModule');

    contenidoModule.controller('ContenidoCtrl', ['$scope', '$state', '$stateParams', '$http', '$compile', 'ContenidoElements', 'ContenidoSectionsEdition', 'dialogs', 'Upload', 'ContenidoImages', 'fontsServices',
        function($scope, $state, $stateParams, $http, $compile, Elements, Section, dialogs, $upload, Images, fonts) {
            // NOTE: implementacion del init
            $scope.init = function() {

                // NOTE: El editmode del scope es para que la edicion de los fields este desactivada inicialmente
                $scope.editmode = false;

                // NOTE: Obtenemos el elemento con el id que recibimos en los parametros
                $scope.element = Elements.get({
                    id: $stateParams.element_id,
                    content: $stateParams.content_id
                }, function(element) {
                    // NOTE: Validacion si lo que se renderizara es un elementos single o se renderizara todo el layout de un elemento de stock
                    if (element.behavior == 'single') {
                        $scope.getSingleLayout();
                    } else {
                        $scope.getStockLayout();
                    }
                });

                // NOTE: Esta variable field_model es para poder almacenar el css de los elementos y modificarlos en tiemo real... asi se puede generar el modelo dinamicamente...
                $scope.field_models = {};

            };

            // NOTE: Corremos el init que es la funcion inicializadora
            $scope.init();

            //NOTE: llama a las fuentes.
            $scope.fonts = fonts.list();

            // NOTE: esconde la barra de upload
            $('.progressBar').hide();

            // NOTE: Esta funcion obtiene el preview de un eleemento single dese python
            $scope.getSingleLayout = function() {
                // NOTE: Obtenemos por medio de $http el html de la seccion que recibimos atraves del elemento

                // TODO: Por el momento se estan llamando los tamanos de las imahenes desfe element.section.snippet pero esta arquitectura debe de cambiar para que el snippet lo llame el elemento no el contenido el probelma es cuando este elemento esta con mas de una seccion por lo tanto tiene dos snippets separados.
                $http.get('/section_preview/' + sessionStorage.site + '/' + $scope.element.section.id).success(function(data) {
                    var layout_cont;
                    layout_cont = '<div class="container">' + data.section + '</div>';
                    $('.inhouse_section_preview_content').html($compile(data.section)($scope));

                    // NOTE: Compila los estio del sitio en general
                    $('.section_styles').append($compile(data.styles)($scope));
                });
            };

            // NOTE: Esta funcion obtiene el layout de un elemento de stock
            $scope.getStockLayout = function() {
                // NOTE: Por medio de htto obtenemos el preview de un elementos de stock que trae todo el layout con la seccion!
                $http.get('/stock_preview/' + sessionStorage.site + '/' + $scope.element.id).success(function(data) {
                    var layout_cont;
                    layout_cont = '<div class="container">' + data.section + '</div>';
                    $('.inhouse_section_preview_content').html($compile(data.section)($scope));

                    // NOTE: Compila los estio del sitio en general
                    $('.section_styles').append($compile(data.styles)($scope));
                });
            };

            // NOTE: Funcion para regresar al panel desde el editor
            $scope.backToPanel = function() {
                $state.go('inhouse.panel', {
                    'page_id': sessionStorage.active_page
                });
            };

            // NOTE: Funcion para guardar los cambios realizados al elemento
            $scope.saveElement = function() {
                // NOTE: Mandar toda la informacion en una sola llamada **no creo que sea facil :(** se van a mandar el elemento para que edite el contenido y todos los estilos pertenecientes a la seccion para que pueda ser grabados.
                var data = {};

                // NOTE: Arriba creamos una variable data en esa variable insertamos una propiedad element que es en la que metemos el elemento de la seccion
                data.element = $scope.element;

                // NOTE: Hacemos un update al edition de la seccion le mandamos como parametro el data que es el que contiene el elemento y los estilos
                $scope.element.styles = $scope.field_models;

                Section.update(data, function(data) {
                    // NOTE: Hacemos esto en el return para que edite el nombre del elemento en la edicion
                    $scope.element.name = data.element.name;
                });
            };

            //NOTE: función para mostrar el area de formato
            $scope.panelChange = function(field) {
                $(".field-options-all").hide();
                $(".modification-to-" + field).show();
                $(".element_edition_format").show();
                $(".element_edition_container").hide();
            };


            //NOTE: función para esconder el area de formato
            $(document).ready(function() {
                $(document).click(function(event) {
                    if ($(event.target).is('#pswitch,#pswitch *,.aparence-panel,.aparence-panel *')) {
                        return;
                    } else {
                        $(".element_edition_format").hide();
                        $(".field-options-all").hide();
                        $(".element_edition_container").show();
                    }
                });
            });

            //NOTE: funcion para alienado y estilizado
            $scope.setModel = function(field, prop, value) {

                if ($scope.field_models[field] === undefined) {
                    $scope.field_models[field] = {};
                }

                if ($scope.field_models[field][prop] == value) {
                    $scope.field_models[field][prop] = '';
                } else {
                    $scope.field_models[field][prop] = value;
                }

                $('.' + field).css(prop, $scope.field_models[field][prop]);
            };

            // NOTE: uploadFiles es la funcion que sube las imagenes a cloudinary
            $scope.uploadFiles = function(files, name_field) {
                // NOTE: Obtenemos el primer archivo del que nos envian el arreglo
                $('.uploadButton').hide();
                $('.progressLoad').show();
                var file = files[0];

                if (file && !file.$error) {
                    $scope.upload = $upload.upload({
                        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
                        url: "https://api.cloudinary.com/v1_1/" +
                            $.cloudinary.config().cloud_name + "/image/upload",
                        data: {
                            upload_preset: $.cloudinary.config().upload_preset,
                            folder: 'v6_site_' + sessionStorage.site,
                            context: 'photo=' + file.name
                        },
                        file: file
                    }).progress(function(e) {
                        var percent = Math.round((e.loaded * 100.0) / e.total);

                        $scope.determinateValueCircle = percent;

                    }).success(function(data) {
                        // TODO: Por el momento se estan llamando los tamanos de las imahenes desfe element.section.snippet pero esta arquitectura debe de cambiar para que el snippet lo llame el elemento no el contenido el probelma es cuando este elemento esta con mas de una seccion por lo tanto tiene dos snippets separados.
                        var url_image = 'https://res.cloudinary.com/' + $.cloudinary.config().cloud_name + '/image/upload/c_limit,q_100/w_' + $scope.element.section.snippet.img_width + ',h_' + $scope.element.section.snippet.img_height + ',c_fit/' + data.public_id + '.' + data.format;

                        var img_html = '<div class="img_' + name_field + '"><div class="img-opts"><img src="' + url_image + '" class="' + name_field + '"><div class="remove" ng-click="removeImage(\'' + name_field + '\')" ><span class="glyphicon icon-basurero"></span></div><div class="edition"><span class="glyphicon icon-lapiz"></span></div></div></div>';

                        $('.img_' + name_field).html($compile(img_html)($scope));
                        $('.progressLoad').delay(3000).fadeOut();

                        var data_element = {};

                        // NOTE: Arriba creamos una variable data en esa variable insertamos una propiedad element que es en la que metemos el elemento de la seccion
                        data_element.element = $scope.element;

                        data_element.element.fields = {};

                        data_element.element.styles = {};

                        data_element.element.fields[name_field] = url_image;

                        Section.update(data_element, function() {
                            $scope.element.fields[name_field] = url_image;
                        });

                    }).error(function(data) {
                        file.result = data;
                    });
                }
            };

            //NOTE Modify the look and fill of the dropzone when files are being dragged over it
            $scope.dragOverClass = function($event) {
                var items = $event.dataTransfer.items;
                var hasFile = false;
                if (items !== null) {
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].kind == 'file') {
                            hasFile = true;
                            break;
                        }
                    }
                } else {
                    hasFile = true;
                }
                return hasFile ? "dragover" : "dragover-err";
            };

            $scope.removeImage = function(image) {

                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {

                    $scope.element.fields[image] = '';
                    var data_element = {};
                    data_element.element = $scope.element;
                    data_element.element.fields = {};
                    data_element.element.styles = {};
                    data_element.element.fields[image] = '';
                    Section.update(data_element, function() {
                        // TODO: Por el momento se estan llamando los tamanos de las imahenes desfe element.section.snippet pero esta arquitectura debe de cambiar para que el snippet lo llame el elemento no el contenido el probelma es cuando este elemento esta con mas de una seccion por lo tanto tiene dos snippets separados.
                        var img_html = '<div class="img_\'' + image + '\'"><div class="img-not-opt-section img-upload" ngf-drop="uploadFiles($files, \'' + image + '\')" ngf-drag-over-class="dragOverClass($event)" ng-model="files" ng-multiple="false"> <div class="add" ngf-select="uploadFiles($files, \'' + image + '\')" resetOnClick="true"><div class="progressLoad"><md-progress-circular md-mode="determinate" value="{{determinateValueCircle}}"></md-progress-circular></div><div class="uploadButton"><span class="icon-agregar2"></span><p>Agregar Imagen</p></div></div></div><img src="http://dummyimage.com/' + $scope.element.section.snippet.img_width + 'x' + $scope.element.section.snippet.img_height + '/"></div></div>';
                        $('.img_' + image).html($compile(img_html)($scope));
                    });
                });

            };


            // NOTE: Esta funcion es para todas las propiedades que tengan que ver con la edición inmediata, reciben el nombre de la propiedad, y el field al que se ingresara.
            $scope.modProp = function(prop, field) {
                // NOTE: Se cambia el css en tiemo real del elemento.
                $('.' + field).css(prop, $scope.field_models[field][prop]);
            };

            $scope.uploadBanner = function(files) {
                $('.progressBar').show();
                $('.ProgressBarUploading').show();
                $('.ProgressBarComplete').hide();
                $scope.determinateValue = 0;
                angular.forEach(files, function(file) {
                    if (file && !file.$error) {
                        file.upload = $upload.upload({
                            acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
                            url: "https://api.cloudinary.com/v1_1/" +
                                $.cloudinary.config().cloud_name + "/image/upload",
                            data: {
                                upload_preset: $.cloudinary.config().upload_preset,
                                folder: 'v6_site_' + sessionStorage.site,
                                context: 'photo=' + file.name
                            },
                            file: file
                        }).progress(function(e) {
                            /*Aqui va el estado*/
                            var percentaje = (e.loaded / e.total) * 100;
                            $scope.determinateValue = percentaje;
                        }).success(function(data) {
                            $('.ProgressBarUploading').hide();
                            $('.ProgressBarComplete').show();
                            Images.create({
                                'id_site': sessionStorage.site,
                                'id_content': $scope.element.content,
                                'element': $scope.element.id,
                                'id_element': $scope.element.id,
                                'public_id': data.public_id,
                                'extension': data.format,
                                'position': 0
                            }, function(data) {
                                $scope.element.images.push(data);
                                $('.progressBar').delay(1500).fadeOut();
                            });
                        }).error(function(data) {
                            file.result = data;
                        });
                    }
                });
            };

            $scope.removeImageStock = function(image, idx) {
                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {
                    Images.destroy({
                        'id': image.id,
                        'content': $scope.element.content,
                        'element': $scope.element.id
                    }, function() {
                        $scope.element.images.splice(idx, 1);
                    });
                });
            };


            // NOTE: Funciones para obtener los valores default para los inputs de edicion.
            setTimeout(function() {
                if ($scope.element.fields.title !== undefined) {
                    $('.font-family-title').attr("value", $('.title').css('font-family'));
                    $('.font-size-title').attr("value", $('.title').css('font-size'));
                    $('.line-height-title').attr("value", $('.title').css('line-height'));
                    $('.color-title .sp-preview-inner').css("background-color", $('.title').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.description !== undefined) {
                    $('.font-family-description').attr("value", $('.description').css('font-family'));
                    $('.font-size-description').attr("value", $('.description').css('font-size'));
                    $('.line-height-description').attr("value", $('.description').css('line-height'));
                    $('.color-description .sp-preview-inner').css("background-color", $('.description').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.caption !== undefined) {
                    $('.font-family-caption').attr("value", $('.caption').css('font-family'));
                    $('.font-size-caption').attr("value", $('.caption').css('font-size'));
                    $('.line-height-caption').attr("value", $('.caption').css('line-height'));
                    $('.color-caption .sp-preview-inner').css("background-color", $('.caption').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.date !== undefined) {
                    $('.font-family-date').attr("value", $('.date').css('font-family'));
                    $('.font-size-date').attr("value", $('.date').css('font-size'));
                    $('.line-height-date').attr("value", $('.date').css('line-height'));
                    $('.color-date .sp-preview-inner').css("background-color", $('.date').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.price !== undefined) {
                    $('.font-size-price').attr("value", $('.price').css('font-size'));
                    $('.color-price .sp-preview-inner').css("background-color", $('.price').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.code !== undefined) {
                    $('.font-size-code').attr("value", $('.code').css('font-size'));
                    $('.color-code .sp-preview-inner').css("background-color", $('.code').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.link !== undefined) {
                    $('.font-size-link').attr("value", $('.link').css('font-size'));
                    $('.color-link .sp-preview-inner').css("background-color", $('.link').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.mail !== undefined) {
                    $('.font-family-mail').attr("value", $('.mail').css('font-family'));
                    $('.font-size-mail').attr("value", $('.mail').css('font-size'));
                    $('.color-mail .sp-preview-inner').css("background-color", $('.mail').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.caption !== undefined) {
                    $('.font-family-caption').attr("value", $('.caption').css('font-family'));
                    $('.font-size-caption').attr("value", $('.caption').css('font-size'));
                    $('.color-caption .sp-preview-inner').css("background-color", $('.caption').css('color'));
                } else {
                    return;
                }

                if ($scope.element.fields.maintitle !== undefined) {
                    $('.font-family-maintitle').attr("value", $('.maintitle').css('font-family'));
                    $('.font-size-maintitle').attr("value", $('.maintitle').css('font-size'));
                    $('.color-maintitle .sp-preview-inner').css("background-color", $('.maintitle').css('color'));
                } else {
                    return;
                }
            }, 3000);
        }
    ]);
})();
