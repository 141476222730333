(function() {
    'use strict';

    /* Controllers */

    var publishModule = angular.module('publishModule');


    publishModule.controller('PublishedtCtrl', ['$scope', 'CuestionarioSites',
        'AuthenticationService',
        function($scope, sites, AuthenticationService) {


            $scope.site = sites.get({
                'id': sessionStorage.site
            }, function() {
                console.log($scope.site.published);
            });

            $scope.user = sessionStorage.user_name;

            $scope.hash_user = sessionStorage.user;

            $scope.logout = function() {
                AuthenticationService.logout();
            };

        }
    ]);
})();
