(function() {
    'use strict';

    /* Controllers */

    var publishModule = angular.module('publishModule');


    publishModule.controller('PaymentCtrl', ['$scope', '$location',
        'CuestionarioSites', 'AuthenticationService', '$state',
        function($scope, $location, sites, AuthenticationService, $state) {
            $scope.selected = false;

            $scope.site = sites.get({
                'id': sessionStorage.site
            }, function() {
                if ($scope.site.hash_invoice !== null &&
                    $scope.site.hash_invoice !== '') {
                    $location.path('/domains');
                }
            });

            $scope.user = sessionStorage.user_name;

            $scope.hash_user = sessionStorage.user;

            $scope.license_type = 'Starter';
            $scope.license_price = '69.99';
            $scope.license_price_annual = '69.99';
            $scope.license_price_month = '6.99';

            $scope.logout = function() {
                AuthenticationService.logout();
            };

            $scope.closePayment = function() {
                $state.go('inhouse');
            };

            $scope.setAnual = function() {
                $scope.license_price = $scope.license_price_annual;
                $(".Month").removeAttr("checked");
            };

            $scope.setMonth = function() {
                $scope.license_price = $scope.license_price_month;
                $(".Annual").removeAttr("checked");
            };

            $scope.getStarter = function() {
                $scope.license_type = 'Paquete Starter';
                $scope.license_price = '69.99';
                $scope.license_price_annual = '69.99';
                $scope.license_price_month = '6.99';
                $scope.selected = true;
                //$state.go('.option');
            };

            $scope.getStandard = function() {
                $scope.license_type = 'Paquete Standard';
                $scope.license_price = '129.99';
                $scope.license_price_annual = '129.99';
                $scope.license_price_month = '12.99';
                $scope.selected = true;
                //$state.go('.option');
            };

            $scope.getPremium = function() {
                $scope.license_type = 'Paquete Premium';
                $scope.license_price = '199.99';
                $scope.license_price_annual = '199.99';
                $scope.license_price_month = '19.99';
                $scope.selected = true;
                //$state.go('.option');
            };

            $scope.resetSelection = function() {
                $scope.selected = false;
                //$event.preventDefault();
            };

            $scope.getDomain = function() {
                $state.go('domains');
            };

            $scope.event_licencia = function(type) {
                ga('send', 'event', 'licencia', 'seleccionar licencia', type);
            };
        }
    ]);
})();
