(function() {
    'use strict';

    /* Controllers */

    var publishModule = angular.module('publishModule');


    publishModule.controller('DomainsCtrl', ['$scope', '$location',
        'CuestionarioSites', 'AuthenticationService', 'checkDomainsService', '$state',
        function($scope, $location, sites, AuthenticationService, domains, $state) {

            $scope.site = sites.get({
                'id': sessionStorage.site
            }, function() {
                if ($scope.site.hash_invoice === null ||
                    $scope.site.hash_invoice === '') {
                    $location.path('/payment');
                } else if ($scope.site.domain !== null) {
                    $location.path('/publish');
                }
            });

            $scope.user = sessionStorage.user_name;

            $scope.hash_user = sessionStorage.user;

            $scope.searchDomain = function($event) {
                $($event.currentTarget).attr('disabled');
                $($event.currentTarget).addClass('circle-loader');
                domains.check({
                    'domain': $scope.domain,
                    'tld': $scope.tld,
                    'id_site': sessionStorage.site,
                    'site': sessionStorage.site
                }, function(data) {
                    $scope.selectedDomain = $scope.domain + '.' + $scope.tld;
                    if (data.availability.status != 'available') {
                        $scope.availability = 'not_avalaible';
                    } else {
                        $scope.availability = data.availability.status;
                    }
                    $scope.domainAvailability = data;
                    $($event.currentTarget).removeAttr('disabled');
                    $($event.currentTarget).removeClass('circle-loader');
                });
            };

            $scope.selectSuggestion = function(domain) {
                $scope.domain =
                    domain.replace('.com', '').replace('.net',
                        '').replace('.info', '').replace('.org', '');
                $scope.tld = domain.replace($scope.domain + '.', '');
                $scope.availability = 'available';
            };

            $scope.registerDomain = function() {
                var domain_name = $scope.domain + '.' + $scope.tld;
                console.log(domain_name);
                domains.register({
                    'domain': domain_name,
                    'id_site': sessionStorage.site,
                    'site': sessionStorage.site
                }, function(data) {
                    if (data.status === true) {
                        $location.path('/publish');
                    } else {
                        alert('Ah sucedido un error intentalo mas tarde');
                    }
                });
            };

            $scope.publish = function() {
                $state.go('publish');
            };

            $scope.logout = function() {
                AuthenticationService.logout();
            };
        }
    ]);
})();
