/* globals Raven */

(function() {
    'use strict';

    angular.module('inhouseExceptions', []).factory('$exceptionHandler', ['$window', '$log',
        function($window, $log) {
            if ($window.Raven) {
                console.log('Using the RavenJS exception handler.');
                if (document.domain == 'panel.getinhouse.com') {
                    Raven.config('https://a686a22fdea1411086728c999a44e612@app.getsentry.com/8926').install();
                } else {
                    Raven.config('https://515baa87d1ab4bd98e927f6e0dedacb6@app.getsentry.com/60539').install();
                }
                return function(exception) {
                    $log.error.apply($log, arguments);
                    Raven.captureException(exception);
                };
            } else {
                console.log('Using the default logging exception handler.');
                return function() {
                    $log.error.apply($log, arguments);
                };
            }
        }
    ]);

})();
