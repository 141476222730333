(function() {
    'use strict';

    /* Controllers */

    var authenticationModule = angular.module('authenticationModule');

    authenticationModule.controller('forgottenCtrl', ['$scope', 'forgottenService',
        function($scope, forgotten) {
            $scope.recover = function() {
                $('.forgotten_button').addClass('circle-loader');

                forgotten.forgotten($scope.email, $scope);
            };

            $scope.success = function() {
                $('.forgotten_button').removeClass('circle-loader');
                $scope.message = 'Hemos enviado un correo para cambiar ' +
                    'tu contraseña a: ' + $scope.email;
            };

            $scope.fail = function() {
                $('.forgotten_button').removeClass('circle-loader');
                $scope.message = 'Sucedio un error al intentar reiniciar  ' +
                    'la contraeña por favor intenta de nuevo';
            };
        }
    ]);

    authenticationModule.controller('recoverCtrl', ['$scope', '$stateParams',
        'recoverService',
        function($scope, $stateParams, recover) {
            $scope.recuperar = function() {
                $('.btn-recover').addClass('circle-loader');
                if ($scope.password == $scope.password_confirm) {
                    var code = $stateParams.code;
                    var hash = $stateParams.hash;
                    recover.recover($scope, $scope.password, hash, code);
                } else {
                    $('.btn-recover').removeClass('circle-loader');
                    $scope.message = 'Las contraseñas no coinciden';
                }
            };

            $scope.fail = function() {
                $('.btn-recover').removeClass('circle-loader');
                $scope.message = 'No se encontro el usuario, o el codigo ' +
                    'es incorrecto por favor intente de nuevo';
            };
        }
    ]);

})();
