(function() {
    'use strict';

    /* Controllers */

    var configurationModule = angular.module('configurationModule');


    configurationModule.controller('AgregarCorreoCtrl', ['$scope', '$location',
        'AuthenticationService', 'CuestionarioSites', 'emailsService',
        function($scope, $location, AuthenticationService, sites, email) {

            $scope.user = sessionStorage.user_name;

            $scope.site = sites.get({
                'id': sessionStorage.site
            });
            $scope.email = {};

            $scope.valid_password = false;
            $scope.matched_password = false;

            $scope.correo_creado = false;

            $scope.logout = function() {
                AuthenticationService.logout();
            };

            $scope.check_password = function() {
                if ($scope.email.password.length < 8) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.length > 32) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/\d/) == -1) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/[a-zA-Z]/) == -1) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
                    $scope.valid_password = false;
                } else {
                    $scope.valid_password = true;
                }
            };

            $scope.match_password = function(password) {
                if ($scope.email.password != password) {
                    $scope.matched_password = false;
                } else {
                    $scope.matched_password = true;
                }
            };

            $scope.addMail = function() {
                if ($scope.valid_password === true && $scope.matched_password === true) {
                    $scope.email.site = sessionStorage.site;
                    $scope.email.id_site = sessionStorage.site;
                    $scope.email.email = $scope.email.local_email + '@' +
                        $scope.site.domain;
                    email.register($scope.email, function(data) {
                        if (data.status != 'error') {
                            $scope.correos.push(data);
                            $scope.correo_creado = true;
                        } else {
                            alert('Ocurrió un error');
                        }
                    }, function() {
                        alert('Ocurrió un error');
                    });
                } else {
                    if ($scope.valid_password !== true) {
                        alert('La contraseña ingresada no es válida');
                    } else if ($scope.matched_password !== true) {
                        alert('Las contraseñas no coiciden');
                    }
                }
            };

        }
    ]);
})();
