// File: js/panel/services.js

(function() {
    'use strict';
    var panelModule = angular.module('panelModule');

    panelModule.factory('PanelSites', ['$resource', function($resource) {
        return $resource(':user/sites/:id', {
            user: sessionStorage.user,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    panelModule.factory('PanelColors', ['$resource', function($resource) {
        return $resource('colors/:id', {
            'id': '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'remove': {
                method: 'DELETE'
            }
        });
    }]);

    panelModule.factory('PanelPages', ['$resource', function($resource) {
        return $resource(':user/:site/menus/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'get': {
                method: 'GET',
                isArray: false
            },
            'update': {
                method: 'PUT'
            },
            'destroy': {
                method: 'DELETE'
            }
        });
    }]);

    panelModule.factory('PanelSnnipetsContents', ['$resource', function($resource) {
        return $resource('/snippet_content/:type', {
            type: '@id_type'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    panelModule.factory('PanelContents', ['$resource', function($resource) {
        return $resource(':user/:site/contents/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'get': {
                method: 'GET',
                isArray: false
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    panelModule.factory('PanelElements', ['$resource', function($resource) {
        return $resource('/:user/:site/:content/elements/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            content: '@id_content',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            },
            'remove': {
                method: 'DELETE'
            }
        });
    }]);

    panelModule.factory('PanelImages', ['$resource', function($resource) {
        return $resource(':user/:site/:content/:element/images/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            content: '@id_content',
            element: '@id_element',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            },
            'destroy': {
                method: 'DELETE'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    panelModule.factory('PanelSections', ['$resource', function($resource) {
        return $resource(':user/:site/:menu/sections/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            menu: '@id_menu',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    panelModule.factory('PanelPagesEdition', ['$resource', function($resource) {
        return $resource(':user/pages_edition', {
            user: sessionStorage.user
        }, {
            'update': {
                method: 'PUT'
            }
        });
    }]);

    panelModule.factory('PanelSnnipets', ['$resource', function($resource) {
        return $resource('/snippets', {}, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    panelModule.factory('PanelLayouts', ['$resource', function($resource) {
        return $resource('/layouts', {}, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    panelModule.factory('PanelSectionsEdition', ['$resource', function($resource) {
        return $resource(':user/sections_edition', {
            user: sessionStorage.user
        }, {
            'order': {
                method: 'POST'
            }
        });
    }]);

    panelModule.factory('PanelStyles', ['$resource', function($resource) {
        return $resource(':user/:site/styles_panel', {
            user: sessionStorage.user,
            site: sessionStorage.site
        }, {
            'update': {
                method: 'PUT'
            }
        });
    }]);

})();
