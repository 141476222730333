// File: js/contenido/services.js

(function() {
    'use strict';
    var contenidoModule = angular.module('contenidoModule');

    contenidoModule.factory('ContenidoSections', ['$resource', function($resource) {
        return $resource(':user/:site/:menu/sections/:id', {
            user: sessionStorage.user,
            menu: sessionStorage.active_page,
            site: sessionStorage.site,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    contenidoModule.factory('ContenidoElements', ['$resource', function($resource) {
        return $resource('/:user/:site/:content/elements/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            content: '@id_content',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            },
            'remove': {
                method: 'DELETE'
            }
        });
    }]);

    contenidoModule.factory('ContenidoImages', ['$resource', function($resource) {
        return $resource(':user/:site/:content/:element/images/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            content: '@id_content',
            element: '@id_element',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            },
            'destroy': {
                method: 'DELETE'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    contenidoModule.factory('ContenidoStocksEdition', ['$resource', function($resource) {
        return $resource(':user/stocks_edition', {
            user: sessionStorage.user
        }, {
            'remove': {
                method: 'PUT'
            },
            'order': {
                method: 'POST'
            }
        });
    }]);

    contenidoModule.factory('ContenidoSectionsEdition', ['$resource', function($resource) {
        return $resource(':user/sections_edition', {
            user: sessionStorage.user
        }, {
            'update': {
                method: 'PUT'
            }
        });
    }]);

    contenidoModule.factory('ContenidoHeaderFooterEdition', ['$resource', function($resource) {
        return $resource(':user/header_footer_edition', {
            user: sessionStorage.user
        }, {
            'update': {
                method: 'PUT'
            }
        });
    }]);

    contenidoModule.factory('ContenidoSites', ['$resource', function($resource) {
        return $resource(':user/sites/:id', {
            user: sessionStorage.user,
            id: '@id'
        }, {
            'get': {
                method: 'GET'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    contenidoModule.factory('fontsServices', ['$resource', function($resource) {
        return $resource('fonta/:id', {
            'id': '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'remove': {
                method: 'DELETE'
            }
        });
    }]);



})();
