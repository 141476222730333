// File: js/contenido/stock/controller.js

(function() {
    'use strict';

    var contenidoModule = angular.module('contenidoModule');

    contenidoModule.controller('FooterCtrl', ['$scope', '$http', '$compile', '$state', 'ContenidoSites',

        function($scope, $http, $compile, $state, Sites) {
            $scope.renderFooter = function() {
                $http.get('/footer_preview/' + sessionStorage.site).success(function(data) {

                    $('.inhouse_section_preview_content').html($compile(data.footer)($scope));
                    // NOTE: Compila los estio del sitio en general
                    $('.section_styles').append($compile(data.styles)($scope));

                });
            };

            $scope.init = function() {
                // NOTE: Se valida si el $promise de el sitio es indefinido
                if ($scope.site.$promise !== undefined) {
                    // NOTE: Se realiza esta operacion para poder obtener el $promise de las request al sitio que se hizo en la ruta /inhouse
                    $scope.site.$promise.then(function(site) {
                        $scope.site = site;
                        $scope.pages = site.menus;
                    });
                }

                $scope.renderFooter();
            };

            $scope.init();

            $scope.saveFooter = function() {
                Sites.update($scope.site, function() {
                    console.log('todo guarda bien');
                });
            };

            // NOTE: Funcion para regresar al panel desde el editor
            $scope.backToPanel = function() {
                if (sessionStorage.active_page === undefined) {
                    sessionStorage.active_page = $scope.site.id_home;
                }

                $state.go('inhouse.panel', {
                    'page_id': sessionStorage.active_page
                });
            };

        }
    ]);
})();
