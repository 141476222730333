if (document.domain == 'panel.getinhouse.com') {
    $.cloudinary.config().cloud_name = 'inhouse';
    $.cloudinary.config().upload_preset = 'suluhp95';
} else {
    $.cloudinary.config().cloud_name = 'inhousestitch';
    $.cloudinary.config().upload_preset = 'nzl8hckp';
}

$.dictionary = {
    'maintitle': 'Edita tus contenidos.',
    'maindescription': 'Tu sitio 100% personalizado</br>' +
        'Coloca la información de tu negocio, ' +
        'agrega imágenes, cambia el tema de color y dale ' +
        'un toque único a tu sitio web personalizándolo ' +
        'a tu manera.',
    'title': 'Edita tus contenidos.',
    'description': 'Tu sitio 100% personalizado</br>' +
        'Coloca la información de tu negocio, ' +
        'agrega imágenes, cambia el tema de color y dale ' +
        'un toque único a tu sitio web personalizándolo ' +
        'a tu manera.',
    'caption': 'Tu sitio 100% personalizado</br>' +
        'Coloca la información de tu negocio, ' +
        'agrega imágenes, cambia el tema de color y dale ' +
        'un toque único a tu sitio web personalizándolo ' +
        'a tu manera.',
    'audio': 'http://soundcloud.com/shaparderlrx/' +
        'the-jackson-5-i-want-you-back-shaparder-' +
        'lrx-remix',
    'video': 'http://www.youtube.com/watch?v=tqRM1Pd1VVs',
    'price': '$149.99',
    'embed': '<iframe width="560" height="315" ' +
        'src="https://www.youtube.com/embed/wGBFuBWxDVU" ' +
        'frameborder="0" allowfullscreen></iframe>',
    'link': 'http://getinhouse.com',
    'target': '_blank'
};

$.images = [
    'INhouse/cocina/9126c3bae396063336920d4468ccc0b6',
    'INhouse/DSC07471',
    'INhouse/cocina/9653c7fec59724c086752e4e18589602',
    'INhouse/naturaleza/DSC02104',
    'INhouse/edificios/DSC01677',
    'INhouse/DSC01344',
    'INhouse/naturaleza/DSC00630',
    'INhouse/cocina/9126c3bae396063336920d4468ccc0b6',
    'INhouse/cocina/abba97ec5aa400537d513ebab10031ca',
    'INhouse/edificios/2b4f1c5db1be8d508508f041f23fa9f7'
];
