// File: js/cuestionario/cuestionario.js

/* jshint loopfunc:true */
(function() {
    'use strict';

    var cuestionarioModule = angular.module('cuestionarioModule');

    cuestionarioModule.controller('CuestionarioCtrl', ['$scope', '$location',
        'CuestionarioSites', 'CuestionarioMenus', 'CuestionarioSections',
        'CuestionarioContents', 'CuestionarioElements', 'CuestionarioTemplates',
        'CuestionarioColors', 'CuestionarioImages', 'CuestionarioSnippets',

        function($scope, $location, sites, menus, sections, contents,
            elements, templates, colors, images, Snippets) {

            $scope.init = function() {
                var formWrap = document.getElementById('fs-form-wrap');

                $scope.site = {};

                $scope.sites = sites.list(function(data) {
                    if (data.length === 0) {
                        $('.btnCancel').hide();
                    }
                });

                /* jshint -W031 */
                new FForm(formWrap, {
                    onReview: function() {
                        $scope.createSite();
                        $('.modal-dialog').removeClass('hide');
                    }
                });
                /* jshint +W031 */

            };

            $scope.init();

            $scope.createSite = function() {
                sites.create({
                    "name": $scope.site.name,
                    "template": 'Basic',
                    "id_role": 1,
                }).$promise.then(function(data) {
                    sessionStorage.site = data.id;
                    $scope.site.id = data.id;
                    $scope.createHome();
                }, function() {
                    console.log('Error al crear el sitio');
                });
            };

            $scope.createHome = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Inicio',
                    "position": 0,
                    "id_type_layout": 1,
                    "extern": "False"
                }).$promise.then(function(data) {
                    $scope.site.home_id = data.id;
                    sites.update({
                        'id': $scope.site.id,
                        'id_home': $scope.site.home_id
                    }).$promise.then(function() {
                        $scope.createBanner();
                    }, function() {
                        console.log('error al actualizar el home');
                    });
                }, function() {
                    console.log('Error al crear el home ');
                });
            };

            //Proceso para la creación de seccion de banner
            $scope.createBanner = function() {

                var fields = [{
                    "name": "title",
                    "type": "text"
                }, {
                    "name": "description",
                    "type": "longtext"
                }, {
                    "name": "link",
                    "type": "link"
                }, {
                    "name": "target",
                    "type": "text"
                }];

                var contentData = {
                    "id_site": $scope.site.id,
                    "name": 'Banners',
                    "behavior": "single",
                    "type": "banner",
                    "image_behavior": "stock",
                    "fields": fields
                };
                contents.create(contentData).$promise.then(function(data) {
                    $scope.addBanner(data.id);
                }, function() {
                    console.log('Error al crear el content del banner');
                });
            };

            $scope.addBanner = function(idContent) {
                elements.create({
                    "name": 'Banners',
                    "position": 0,
                    "id_content": idContent,
                    "id_site": $scope.site.id
                }).$promise.then(function(data) {
                    $scope.banner_element = data;
                    $scope.banner_element.id_content = idContent;
                    $scope.banner_element.id_site = $scope.site.id;
                    sections.create({
                        "id_site": $scope.site.id,
                        "id_menu": $scope.site.home_id,
                        "name": 'Banners',
                        "id_content": idContent,
                        "position": 0
                    }).$promise.then(function() {
                        $scope.imagesBanner();
                        //$scope.createDescription();
                    }, function() {
                        console.log('Error al crear la seccion de banner');
                    });
                }, function() {
                    console.log('error al crear el elemento del banner');
                });
            };

            $scope.imagesBanner = function() {

                for (var i = 0; i < 4; i++) {
                    var random_image = Math.floor((Math.random() * 10) + 1);
                    var image = '';
                    switch (random_image) {
                        case 1:
                            image = 'INhouse/cocina/9126c3bae396063336920d4468ccc0b6';
                            break;
                        case 2:
                            image = 'INhouse/DSC07471';
                            break;
                        case 3:
                            image = 'INhouse/cocina/9653c7fec59724c086752e4e18589602';
                            break;
                        case 4:
                            image = 'INhouse/naturaleza/DSC02104';
                            break;
                        case 5:
                            image = 'INhouse/edificios/DSC01677';
                            break;
                        case 6:
                            image = 'INhouse/DSC01344';
                            break;
                        case 7:
                            image = 'INhouse/naturaleza/DSC00630';
                            break;
                        case 8:
                            image = 'INhouse/cocina/9126c3bae396063336920d4468ccc0b6';
                            break;
                        case 9:
                            image = 'INhouse/cocina/abba97ec5aa400537d513ebab10031ca';
                            break;
                        case 10:
                            image = 'INhouse/edificios/2b4f1c5db1be8d508508f041f23fa9f7';
                            break;
                    }
                    images.create({
                        'id_site': $scope.site.id,
                        'id_content': $scope.banner_element.id_content,
                        'element': $scope.banner_element.id,
                        'id_element': $scope.banner_element.id,
                        'public_id': image,
                        'extension': 'jpg',
                        'position': 0
                    });
                }
                $scope.bannerFields();
            };

            $scope.bannerFields = function() {
                $scope.site.banner_description = 'Cuando subas una fotografía,' +
                    ' asegúrate de que el título o nombre de la imagen esté ' +
                    'relacionada con tu marca. Con nuestro sistema, INhouse ' +
                    'ajusta automáticamente las imágenes al tamaño del diseño ' +
                    'de tu sitio web para que no te preocupes por editar o ' +
                    'buscar imágenes con medidas específicas.';
                $scope.banner_element.fields = {
                    'title': 'Agrega imágenes.',
                    'description': $scope.site.banner_description
                };
                elements.update($scope.banner_element).$promise.then(function() {
                    $scope.createDescription();
                }, function(errResponse) {
                    console.log(errResponse);
                });
            };

            $scope.createDescription = function() {
                var fields = [{
                    "name": "title",
                    "type": "text"
                }, {
                    "name": "description",
                    "type": "longtext"
                }];
                var contentData = {
                    "id_site": $scope.site.id,
                    "name": 'Descripción',
                    "behavior": "single",
                    "id_type": 7,
                    "image_behavior": "none",
                    "fields": fields
                };
                contents.create(contentData).$promise.then(function(data) {
                    $scope.addDescription(data.id);
                }, function() {
                    console.log('Error al crear el content de descripción');
                });
            };

            $scope.addDescription = function(idContent) {
                elements.create({
                    "id_site": $scope.site.id,
                    "name": 'Descripción',
                    "position": 1,
                    "id_content": idContent
                }).$promise.then(function(data) {
                    $scope.description_element = data;
                    $scope.description_element.id_content = idContent;
                    $scope.description_element.id_site = $scope.site.id;

                    sections.create({
                        "id_site": $scope.site.id,
                        "id_menu": $scope.site.home_id,
                        "name": 'Descripción',
                        "id_content": idContent,
                        "position": 1
                    }).$promise.then(function() {
                        $scope.descriptionFields();
                    }, function() {
                        console.log('Error al crear la seccion de descripcion');
                    });
                }, function() {
                    console.log('error al crear el elemento de descripcion');
                });
            };

            $scope.descriptionFields = function() {
                $scope.site.description = 'Con INhouse no tienes que esperar ' +
                    'para actualizar la información de tu sitio. Crea, edita y ' +
                    'actualiza tu web con solo tener acceso a internet.';
                $scope.description_element.fields = {
                    'title': 'Actualización inmediata de tus contenidos.',
                    'description': $scope.site.description
                };
                elements.update($scope.description_element).$promise.then(function() {
                    $scope.articlesMenu();
                }, function(errResponse) {
                    console.log(errResponse);
                });
            };

            $scope.articlesMenu = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Articulos',
                    "position": 1,
                    "id_type_layout": 4,
                    "extern": "False"
                }).$promise.then(function(data) {
                    $scope.site.menu_articles = data.id;
                    $scope.createArticles();
                }, function() {
                    console.log('Error al crear el menu de articulos');
                });
            };

            $scope.createArticles = function() {

                Snippets.get({
                    "behavior": "stock",
                    "type": 7,
                    "just_one": true
                }, function(snippet) {
                    var fields = snippet.fields;

                    var contentData = {
                        "id_site": $scope.site.id,
                        "name": 'Articulos',
                        "behavior": "stock",
                        "id_type": 7,
                        "image_behavior": snippet.image_behavior,
                        "fields": fields
                    };

                    contents.create(contentData).$promise.then(function(data) {
                        $scope.addArticles(data, snippet);
                    }, function() {
                        console.log('Error al crear el content de los articulos');
                    });

                });
            };

            $scope.addArticles = function(content, snippet) {
                sections.create({
                    "id_site": $scope.site.id,
                    "id_menu": $scope.site.menu_articles,
                    "name": "Articulos",
                    "id_content": content.id,
                    "position": 3
                }).$promise.then(function() {
                    $scope.HomeArticles(content, snippet);
                }, function() {
                    console.log('fallo la creación de las secciones de articulos');
                });
            };

            $scope.HomeArticles = function(content, snippet) {
                sections.create({
                    "id_site": $scope.site.id,
                    "id_menu": $scope.site.home_id,
                    "name": "Articulos",
                    "id_content": content.id,
                    "position": 3
                }).$promise.then(function() {
                    $scope.stockArticles(content, snippet);
                }, function() {
                    console.log('fallo la creación de las secciones ' +
                        'de articulos del home');
                });
            };

            $scope.stockArticles = function(content, snippet) {
                var counter = 0;

                var fields_content = {};

                angular.forEach(content.fields, function(field) {
                    if (field.type == 'image') {
                        var random_image = Math.floor((Math.random() * 9) + 1);
                        fields_content[field.name] = 'https://res.cloudinary.com/inhouse/image/upload/c_limit,q_100/w_' + snippet.img_width + ',h_' + snippet.img_height + ',c_fit/' + $.images[random_image] + '.jpg';
                    } else {
                        fields_content[field.name] = $.dictionary[field.name];
                    }
                });

                for (var i = 0; i < 5; i++) {
                    elements.create({
                        "id_site": $scope.site.id,
                        "name": "Articulo",
                        "position": counter,
                        "id_content": content.id
                    }).$promise.then(function(data) {
                        var random_image = Math.floor((Math.random() * 10) + 1);
                        var image = '';
                        switch (random_image) {
                            case 1:
                                image = 'INhouse/cocina/9126c3bae396063336920d4468ccc0b6';
                                break;
                            case 2:
                                image = 'INhouse/DSC07471';
                                break;
                            case 3:
                                image = 'INhouse/cocina/9653c7fec59724c086752e4e18589602';
                                break;
                            case 4:
                                image = 'INhouse/naturaleza/DSC02104';
                                break;
                            case 5:
                                image = 'INhouse/edificios/DSC01677';
                                break;
                            case 6:
                                image = 'INhouse/DSC01344';
                                break;
                            case 7:
                                image = 'INhouse/naturaleza/DSC00630';
                                break;
                            case 8:
                                image = 'INhouse/cocina/9126c3bae396063336920d4468ccc0b6';
                                break;
                            case 9:
                                image = 'INhouse/cocina/abba97ec5aa400537d513ebab10031ca';
                                break;
                            case 10:
                                image = 'INhouse/edificios/2b4f1c5db1be8d508508f041f23fa9f7';
                                break;
                        }
                        var article = data;

                        images.create({
                            'id_site': $scope.site.id,
                            'id_content': content.id,
                            'element': article.id,
                            'id_element': article.id,
                            'public_id': image,
                            'extension': 'jpg',
                            'position': 0
                        });

                        article.id_content = content.id;
                        article.id_site = $scope.site.id;
                        article.fields = fields_content;
                        elements.update(article).$promise.then(function() {

                            counter++;
                            if (counter == 5) {
                                $scope.headerMenu();
                            }
                        }, function(errResponse) {
                            console.log(errResponse);
                        });
                    }, function() {
                        console.log('Error al crear stock de articulos');
                    });
                }
            };

            $scope.headerMenu = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Header',
                    "position": 3,
                    "id_type_layout": 2,
                    "extern": "False"
                }).$promise.then(function(data) {
                    $scope.site.header = data.id;
                    $scope.footerMenu();
                }, function() {
                    console.log('Error al crear el header');
                });
            };

            $scope.footerMenu = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Footer',
                    "position": 4,
                    "id_type_layout": 3,
                    "extern": "False"
                }).$promise.then(function() {
                    $scope.internaArticulos();
                }, function() {
                    console.log('Error al crear el footer');
                });
            };

            $scope.internaArticulos = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Interna Articulos',
                    "position": 5,
                    "id_type_layout": 5,
                    "extern": "False"
                }).$promise.then(function() {
                    $scope.internaProductos();
                }, function() {
                    console.log('Error al crear la interna de articulos');
                });
            };

            $scope.internaProductos = function() {
                menus.create({
                    "id_site": $scope.site.id,
                    "name": 'Interna Productos',
                    "position": 6,
                    "id_type_layout": 6,
                    "extern": "False"
                }).$promise.then(function() {
                    sessionStorage.site = $scope.site.id;
                    sessionStorage.home = $scope.site.home_id;
                    $location.path('inhouse');
                }, function() {
                    console.log('Error al crear la interna de productos');
                });
            };
        }
    ]);
})();
