// File: js/panel/controller.js

(function() {
    'use strict';

    var panelModule = angular.module('panelModule');

    panelModule.controller('PanelCtrl', ['$scope', '$stateParams', '$http', '$compile', '$state', 'PanelSections', 'PanelPages', 'dialogs', '$modal', 'PanelSites', 'PanelSectionsEdition', 'PanelStyles',
        function($scope, $stateParams, $http, $compile, $state, Sections, Pages, dialogs, $modal, Sites, SectionsEdition, Styles) {

            $scope.renderPanel = function() {
                // NOTE: No vale la pena hacer un servicio para esta sola llamada, asi que hacemos un get directo a $http y asi obtenemos un json que nos devuelve el html del sitio y los estilos de este
                $http.get('/panel_preview/' + sessionStorage.site + '/' +
                    $scope.page_id).success(function(data) {

                    // NOTE: Compila el header dentro del html
                    $('.inhouse_site_preview_container').html($compile(data.header)($scope));

                    // NOTE: Compila todas las secciones dentro del elemento
                    $('.inhouse_site_preview_container').append($compile(data.page)($scope));

                    // NOTE: Compila el footer dentro del elemento
                    $('.inhouse_site_preview_container').append($compile(data.footer)($scope));

                    // NOTE: Compila los estio del sitio en general
                    $('.page_styles').append($compile(data.styles)($scope));

                    $(".inhouse_site_preview_container").sortable({
                        disabled: true
                    });
                });

                // NOTE: Propiedades del sortable

                var el = document.getElementById('SectionsSortable');

                var sortable;

                sortable = Sortable.create(el, {
                    animation: 150,
                    handle: '.handle-move',
                    ghostClass: "sortable-ghost",
                    chosenClass: "sortable-chosen",
                    draggable: ".inhouse_section_preview>",
                    dataIdAttr: 'id',
                    forceFallback: false,
                    onEnd: function() {
                        var sections_array = $(".inhouse_site_preview_container").sortable("toArray");
                        SectionsEdition.order({
                            'sections': sections_array
                        });
                    }
                });
            };

            $scope.init = function() {

                // NOTE: Se obtiene por parametros el id de la pagina que es equivalente al menu seleccionado
                $scope.page_id = $stateParams.page_id;


                // NOTE: Se valida si el $promise de el sitio es indefinido
                if ($scope.site.$promise !== undefined) {
                    // NOTE: Se realiza esta operacion para poder obtener el $promise de las request al sitio que se hizo en la ruta /inhuse
                    $scope.site.$promise.then(function(site) {
                        $scope.site = site;
                        $scope.pages = site.menus;
                        $scope.renderPanel();
                    });

                } else {
                    $scope.renderPanel();
                }

                $scope.styles = {};
            };

            // NOTE: Corremos el init que es la funcion inicializadora
            $scope.init();

            // NOTE: Funcion para poder editar el menu del sitio (las ediciones generales como logo redes sociales o tipo de snippet diseno del header y esas cosas)

            $scope.selectHeader = function() {
                $state.go('inhouse.header');
            };

            $scope.selectFooter = function() {
                $state.go('inhouse.footer');
            };


            // NOTE: Esta funcion es la encargada de redirigir el panel cuando seleccionan una seccion
            $scope.selectSection = function(section_id) {

                sessionStorage.active_page = $scope.page_id;

                Sections.get({
                    'menu': $scope.page_id,
                    'id': section_id
                }, function(section) {
                    // NOTE: Validammos el behavior de la seccion
                    if (section.content.behavior == 'single') {
                        // NOTE: Si el behavior es single lo mandamos directamente al editor de contenido con el id del contenido y id del elemento coo parametros
                        $state.go('inhouse.contenido', {
                            'element_id': section.content.elements[0].id,
                            'content_id': section.content.elements[0].id_content
                        });
                    } else {
                        // NOTE: Si el elemento es un stock lo mandamos a la vista de stock con el id del stock
                        $state.go('inhouse.stock', {
                            'stock_id': section.id
                        });
                    }
                });
            };

            $scope.addSection = function(id_section) {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/sections.html',
                    controller: 'pageSectionsCtrl',
                    size: 'lg',
                    windowClass: 'sections-page',
                    resolve: {
                        site: function() {
                            return $scope.site;
                        },
                        page: function() {
                            return $scope.page_id;
                        }
                    }
                }).result.then(function(data) {
                    var section = data;

                    $http.get('/new_section/' + sessionStorage.site + '/' +
                        section.id).success(function(data) {

                        if (id_section != 'header') {
                            $($compile(data.section)($scope)).insertAfter($('.inhouse_section_preview.section_' + id_section));
                        } else {
                            $($compile(data.section)($scope)).insertAfter($('.inhouse-site-menu-container'));
                        }

                        // NOTE: Compila los estio del sitio en general
                        $('.page_styles').append($compile(data.styles)($scope));
                    });

                });
            };

            // NOTE: Funcion que es tutilizada para "eliminar una seccion"
            $scope.removeSection = function(section_id) {
                // NOTE: El dialog es la confirmacion de si desea eliminar el elemento
                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {
                    // NOTE: Realmente no se elimina la seccion simplemente cambia a estado oculto.
                    Sections.update({
                        'id_menu': $scope.page_id,
                        'id': section_id,
                        'is_hide': true
                    }).$promise.then(function() {
                        $('.section_' + section_id).remove();
                    });
                });

            };

            // NOTE: Fucnion para cambiar el menu en el que se encuentra el panel.
            $scope.setMenu = function(page_id) {
                $state.go('inhouse.panel', {
                    'page_id': page_id
                });
            };

            // NOTE: Funcion que abre el modal de edicion de paginas de Menu
            $scope.openPagesEdition = function() {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/edition_pages.html',
                    controller: 'pagesEditionCtrl',
                    size: 'sm',
                    windowClass: 'edition-page',
                    resolve: {
                        site: function() {
                            return $scope.site;
                        }
                    }
                }).result.then(function(add_page) {
                    if (add_page === true) {
                        $scope.openPagesCreation();
                    }
                }, function() {
                    Sites.get({
                        'id': sessionStorage.site
                    }, function(data) {
                        $scope.site.menus = data.menus;
                        $scope.pages = data.menus;
                    });
                });
            };

            // NOTE: Funcion que abre el modal de creacion de menu.
            $scope.openPagesCreation = function() {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/page_name.html',
                    controller: 'pageNameCtrl',
                    size: 'md',
                    windowClass: 'add-page',
                    resolve: {
                        site: function() {
                            return $scope.site;
                        }
                    }
                }).result.then(function(page) {
                    // NOTE: Validamos si la pagina que creamos recien es una pagina vacia o es una de productos.. o etc
                    if (page.type != 'empty') {
                        // NOTE: Si no es una pagina en blanco abrimos el modal que nos muestra los snippets disponibles al tipo de pagina seleccionada
                        $scope.openLayoutsPages(page);
                    } else {
                        // NOTE: Si la pagina es una pagina en blanco enviamos directamente a agregar la pagian
                        $scope.addPage(page);
                    }
                });
            };

            // NOTE: Abre el modal de los snippets disponibles para el tipo de pagina
            $scope.openLayoutsPages = function(page) {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/page_layout.html',
                    controller: 'pageLayoutCtrl',
                    size: 'lg',
                    windowClass: 'add-page',
                    resolve: {
                        site: function() {
                            return $scope.site;
                        },
                        page: function() {
                            return page;
                        }
                    }
                }).result.then(function(page) {
                    // NOTE: Agrega la pagina nueva al arregelo y redirige a la pagina seleccionada
                    $scope.pages.push(page);
                    $state.go('inhouse.panel', {
                        'page_id': page.id
                    });
                });
            };

            // NOTE: Esta funcion sirve para agregar la nueva pagina de menu
            $scope.addPage = function(page) {
                // NOTE: Hacemos una request post para incestar el nuevo elemento
                Pages.create({
                    "id_site": $scope.site.id,
                    "name": page.name,
                    "position": $scope.pages.length + 1,
                    "id_type_layout": 4,
                    "extern": false,
                    "is_submenu": false
                }).$promise.then(function(data) {
                    $scope.pages.push(data);
                    // NOTE: En el promise de success validamos el tipo de pagina que insertamos recien
                    $state.go('inhouse.panel', {
                        'page_id': data.id
                    });

                });
            };

            // NOTE: Funcion que nos sirve para cambiar el layout del header, abre un modal que contiene todos los estilos del header
            $scope.updateLayout = function(id_layout, type_layout) {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/header.html',
                    size: 'lg',
                    windowClass: 'change-snippet',
                    controller: 'LayoutCtrl',
                    resolve: {
                        layout: function() {
                            return id_layout;
                        },
                        type_layout: function() {
                            return type_layout;
                        }
                    }
                }).result.then(function() {
                    $scope.renderPanel();
                });
            };

            // NOTE: Funcion que nos sirve para cambiar el snippet de una seccion
            $scope.updateSnippet = function(id_section) {
                $modal.open({
                    animation: true,
                    templateUrl: 'partials/panel/modals/snippets.html',
                    size: 'lg',
                    windowClass: 'change-snippet',
                    controller: 'SnippetCtrl',
                    resolve: {
                        id_section: function() {
                            return id_section;
                        },
                        id_page: function() {
                            return $scope.page_id;
                        }
                    }
                }).result.then(function() {
                    $scope.renderPanel();
                });
            };

            // NOTE: Esta funcion es para guardar el estilo de los sitios
            $scope.setGeneralStyle = function() {
                Styles.update({
                    'styles': $scope.styles
                });
            };

            //NOTE: Funciones para el pop over the background
            $scope.changeBackground = function(color) {
                $('.inhouse_site_preview_container').css('background-color', color);
            };

            //NOTE: Funciones para los cambios en titulo y descripcion
            $scope.changeTitleFamily = function(prop) {
                $('.inhouse_site_preview_container .title').css('font-family', prop);
                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitlesColor = function(color) {
                $('.inhouse_site_preview_container .title').css('color', color);
            };

            $scope.changeTitleFont = function(prop) {
                $('.inhouse_site_preview_container .title').css('font-size', prop);
                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleBold = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('font-weight') == 'bold') {
                    $('.inhouse_site_preview_container .title').css('font-weight', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('font-weight', 'bold');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleItalic = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('font-style') == 'italic') {
                    $('.inhouse_site_preview_container .title').css('font-style', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('font-style', 'italic');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleUnderline = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-decoration') == 'underline') {
                    $('.inhouse_site_preview_container .title').css('text-decoration', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-decoration', 'underline');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleLineT = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-decoration') == 'line-through') {
                    $('.inhouse_site_preview_container .title').css('text-decoration', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-decoration', 'line-through');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleLeft = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-align') == 'left') {
                    $('.inhouse_site_preview_container .title').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-align', 'left');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleCenter = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-align') == 'center') {
                    $('.inhouse_site_preview_container .title').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-align', 'center');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleRight = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-align') == 'right') {
                    $('.inhouse_site_preview_container .title').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-align', 'right');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeTitleJustify = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .title').css('text-align') == 'justify') {
                    $('.inhouse_site_preview_container .title').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .title').css('text-align', 'justify');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            //Parrafos

            $scope.changeParrafFamily = function(prop) {
                $('.inhouse_site_preview_container .description').css('font-family', prop);
                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafsColor = function(color) {
                $('.inhouse_site_preview_container .description').css('color', color);
            };

            $scope.changeParrafFont = function(prop) {
                $('.inhouse_site_preview_container .description').css('font-size', prop);
                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafBold = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('font-weight') == 'bold') {
                    $('.inhouse_site_preview_container .description').css('font-weight', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('font-weight', 'bold');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafItalic = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('font-style') == 'italic') {
                    $('.inhouse_site_preview_container .description').css('font-style', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('font-style', 'italic');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafUnderline = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-decoration') == 'underline') {
                    $('.inhouse_site_preview_container .description').css('text-decoration', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-decoration', 'underline');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafLineT = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-decoration') == 'line-through') {
                    $('.inhouse_site_preview_container .description').css('text-decoration', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-decoration', 'line-through');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafLeft = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-align') == 'left') {
                    $('.inhouse_site_preview_container .description').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-align', 'left');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafCenter = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-align') == 'center') {
                    $('.inhouse_site_preview_container .description').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-align', 'center');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafRight = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-align') == 'right') {
                    $('.inhouse_site_preview_container .description').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-align', 'right');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            $scope.changeParrafJustify = function(element, prop, value) {

                $scope.styles[element] = {};

                if ($('.inhouse_site_preview_container .description').css('text-align') == 'justify') {
                    $('.inhouse_site_preview_container .description').css('text-align', 'inherit');
                    $scope.styles[element][prop] = 'inherit';

                } else {
                    $('.inhouse_site_preview_container .description').css('text-align', 'justify');
                    $scope.styles[element][prop] = value;
                }

                Styles.update({
                    'styles': $scope.styles
                });
            };

            //NOTE: Funciones para la eleccion de colores en general

            $scope.choosingColorOne = function(color) {
                $('.inhouse_site_preview_container .custom_1').css('color', color);

                $('.inhouse_site_preview_container .border_img_custom_1 img').css('border-color', color);

                $('.inhouse_site_preview_container .base_custom_1').css('background-color', color);
            };

            $scope.choosingColorTwo = function(color) {
                $('.inhouse_site_preview_container .custom_2').css('color', color);

                $('.inhouse_site_preview_container .border_img_custom_2 img').css('border-color', color);

                $('.inhouse_site_preview_container .base_custom_2').css('background-color', color);
            };

            $scope.base25 = function() {
                if ($scope.site.color_3 == "#dcdce7") {
                    $scope.site.color_3 = "transparent";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "transparent");
                    $scope.site.color_4 = "transparent";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "transparent");
                    $scope.site.color_5 = "transparent";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "transparent");
                } else {
                    $scope.site.color_3 = "#dcdce7";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "#dcdce7");
                    $('.inhouse_site_preview_container .base_color_1').css('color', "#dcdce7");
                    $scope.site.color_4 = "#cacad7";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "#cacad7");
                    $('.inhouse_site_preview_container .base_color_2').css('color', "#cacad7");
                    $scope.site.color_5 = "#bdbdca";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "#bdbdca");
                    $('.inhouse_site_preview_container .base_color_3').css('color', "#bdbdca");
                }
            };

            $scope.base50 = function() {
                if ($scope.site.color_3 == "#adadbb") {
                    $scope.site.color_3 = "transparent";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "transparent");
                    $scope.site.color_4 = "transparent";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "transparent");
                    $scope.site.color_5 = "transparent";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "transparent");
                } else {
                    $scope.site.color_3 = "#adadbb";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "#adadbb");
                    $('.inhouse_site_preview_container .base_color_1').css('color', "#adadbb");
                    $scope.site.color_4 = "#9c9cad";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "#9c9cad");
                    $('.inhouse_site_preview_container .base_color_2').css('color', "#9c9cad");
                    $scope.site.color_5 = "#86869a";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "#86869a");
                    $('.inhouse_site_preview_container .base_color_3').css('color', "#86869a");
                }
            };

            $scope.base75 = function() {
                if ($scope.site.color_3 == "#78788a") {
                    $scope.site.color_3 = "transparent";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "transparent");
                    $scope.site.color_4 = "transparent";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "transparent");
                    $scope.site.color_5 = "transparent";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "transparent");
                } else {
                    $scope.site.color_3 = "#78788a";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "#78788a");
                    $('.inhouse_site_preview_container .base_color_1').css('color', "#78788a");
                    $scope.site.color_4 = "#646474";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "#646474");
                    $('.inhouse_site_preview_container .base_color_2').css('color', "#646474");
                    $scope.site.color_5 = "#4d4d5d";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "#4d4d5d");
                    $('.inhouse_site_preview_container .base_color_3').css('color', "#4d4d5d");
                }
            };

            $scope.base100 = function() {
                if ($scope.site.color_3 == "#3e3e4a") {
                    $scope.site.color_3 = "transparent";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "transparent");
                    $scope.site.color_4 = "transparent";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "transparent");
                    $scope.site.color_5 = "transparent";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "transparent");
                } else {
                    $scope.site.color_3 = "#3e3e4a";
                    $('.inhouse_site_preview_container .base_1').css('background-color', "#3e3e4a");
                    $('.inhouse_site_preview_container .base_color_1').css('color', "#3e3e4a");
                    $scope.site.color_4 = "#2a2a33";
                    $('.inhouse_site_preview_container .base_2').css('background-color', "#2a2a33");
                    $('.inhouse_site_preview_container .base_color_2').css('color', "#2a2a33");
                    $scope.site.color_5 = "#0f0f13";
                    $('.inhouse_site_preview_container .base_3').css('background-color', "#0f0f13");
                    $('.inhouse_site_preview_container .base_color_3').css('color', "#0f0f13");
                }
            };

            $scope.applyColor = function(invoice) {
                if (invoice !== 'null') {

                    dialogs.create('partials/dialogs/colorApply.html', 'ConfirmCtrl', {}, {
                        'windowClass': 'delete',
                        'size': 'sm'

                    }).result.then(function() {
                        Sites.update($scope.site);
                    });
                } else {
                    alert('No es posible aplicar los cambios');
                }
            };

            //Funcion para agregar los default value de los pop-over
            setTimeout(function() {
                if ($(".inhouse_site_preview_container .title") !== undefined) {
                    $('.font-family-titles').attr("value", $('.inhouse_site_preview_container .title').css('font-family'));
                    $('.font-size-titles').attr("value", $('.inhouse_site_preview_container .title').css('font-size'));
                    $('.color-titles .sp-preview-inner').css("background-color", $('.inhouse_site_preview_container .title').css('color'));
                } else {
                    return;
                }

                if ($(".inhouse_site_preview_container .description") !== undefined) {
                    $('.font-family-parraf').attr("value", $('.inhouse_site_preview_container .description').css('font-family'));
                    $('.font-size-parraf').attr("value", $('.inhouse_site_preview_container .description').css('font-size'));
                    $('.color-title .sp-preview-inner').css("background-color", $('.inhouse_site_preview_container .description').css('color'));
                } else {
                    return;
                }

                if ($(".inhouse_site_preview_container .color_1") !== undefined) {
                    $('.color-color_1 .sp-preview-inner').css("background-color", $('.inhouse_site_preview_container .color_1').css('color'));
                } else {
                    return;
                }

                if ($(".inhouse_site_preview_container .color_2") !== undefined) {
                    $('.color-color_2 .sp-preview-inner').css("background-color", $('.inhouse_site_preview_container .color_2').css('color'));
                } else {
                    return;
                }

                if ($(".inhouse_site_preview_container .background") !== undefined) {
                    $('.color-background .sp-preview-inner').css("background-color", $('.inhouse_site_preview_container').css('background-color'));
                } else {
                    return;
                }


            }, 2000);
        }
    ]);
})();
