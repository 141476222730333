// File: js/cuestionario/services.js

(function() {
    'use strict';
    var cuestionarioModule = angular.module('cuestionarioModule');

    cuestionarioModule.factory('CuestionarioSites', ['$resource', function($resource) {
        return $resource(':user/sites/:id', {
            user: sessionStorage.user,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioMenus', ['$resource', function($resource) {
        return $resource(':user/:site/menus/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioSections', ['$resource', function($resource) {
        return $resource(':user/:site/:menu/sections/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            menu: '@id_menu',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioContents', ['$resource', function($resource) {
        return $resource(':user/:site/contents/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioElements', ['$resource', function($resource) {
        return $resource('/:user/:site/:content/elements/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            content: '@id_content',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioTemplates', ['$resource', function($resource) {
        return $resource('templates/:id', {}, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioColors', ['$resource', function($resource) {
        return $resource('colors/:id', {
            'id': '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioImages', ['$resource', function($resource) {
        return $resource(':user/:site/:content/:element/images/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            content: '@id_content',
            element: '@id_element',
            id: '@id'
        }, {
            'create': {
                method: 'POST'
            }
        });
    }]);

    cuestionarioModule.factory('CuestionarioSnippets', ['$resource', function($resource) {
        return $resource('/snippets', {}, {
            'list': {
                get: 'GET',
            }
        });
    }]);

})();
