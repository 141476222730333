// File: js/contenido/directives.js

(function() {
    'use strict';

    var inhouseModule = angular.module('inhouseModule');

    inhouseModule.directive("lastSocialDirective", function() {
        return function(scope) {
            if (scope.$last) {


                (function($) {
                    $(function() {
                        var jcarousel = $('.jcarousel');

                        jcarousel
                            .on('jcarousel:reload jcarousel:create', function() {
                                var carousel = $(this),
                                    width = carousel.innerWidth();

                                if (width >= 600) {
                                    width = width / 3;
                                } else if (width >= 350) {
                                    width = width / 2;
                                }

                                carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
                            })
                            .jcarousel({
                                wrap: 'circular',
                            });

                        $('.icon-left')
                            .jcarouselControl({
                                target: '-=1'
                            });

                        $('.icon-right')
                            .jcarouselControl({
                                target: '+=1'
                            });

                        $('.jcarousel-pagination')
                            .on('jcarouselpagination:active', 'a', function() {
                                $(this).addClass('active');
                            })
                            .on('jcarouselpagination:inactive', 'a', function() {
                                $(this).removeClass('active');
                            })
                            .on('click', function(e) {
                                e.preventDefault();
                            })
                            .jcarouselPagination({
                                perPage: 1,
                                item: function(page) {
                                    return '<a href="#' + page + '">' + page + '</a>';
                                }
                            });
                    });
                })(jQuery);
            }
        };
    });

})();
