// File: js/inhouse/services.js

(function() {
    'use strict';
    var inhouseModule = angular.module('inhouseModule');

    inhouseModule.factory('InhouseSites', ['$resource', function($resource) {
        return $resource(':user/sites/:id', {
            user: sessionStorage.user,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'destroy': {
                method: 'DELETE'
            }
        });
    }]);

    inhouseModule.factory('InhouseSocialNetworks', ['$resource', function($resource) {
        return $resource('social_networks', {}, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

    inhouseModule.factory('InhousesiteNetworks', ['$resource', function($resource) {
        return $resource(':user/:site/site_networks/:id', {
            user: sessionStorage.user,
            site: sessionStorage.site,
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'create': {
                method: 'POST'
            },
            'destroy': {
                method: 'DELETE'
            }
        });
    }]);

    inhouseModule.factory('emailsService', ['$resource', function($resource) {
        return $resource(':user/:site/email/:id', {
            user: sessionStorage.user,
            site: '@id_site',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            },
            'register': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }]);


})();
