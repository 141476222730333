// File: js/panel/login/services.js
/* globals CryptoJS */

(function() {
    'use strict';

    var authenticationModule = angular.module('authenticationModule');

    authenticationModule.factory('AuthenticationService', ['$http', '$state', '$window', 'SessionService',
        'getToken', 'removeToken', '$rootScope', 'localStorageService',
        function($http, $state, $window, SessionService, token, remove_token, $rootScope, localStorage) {
            return {

                login: function(email, password, callback) {
                    $http.post('https://account.getinhouse.com/session/login', {
                        'email': email,
                        'password': password
                    }).success(function(response) {
                        response.success = true;
                        callback(response);
                    }).error(function(response) {
                        callback(response);
                    });
                },

                setCredentials: function(data, keep) {

                    sessionStorage.user = data.user.hash;
                    sessionStorage.user_name = data.user.name;

                    token.get_token(function(session) {
                        sessionStorage.csrf = session.csrf;
                        sessionStorage.logged = true;
                        if (keep === true) {
                            localStorage.set('user', data.user.hash);
                            localStorage.set('name', data.user.name);
                            localStorage.set('logged', true);
                        }
                        localStorage.set('is_logged', true);
                    });
                },

                logout: function() {
                    remove_token.remove_token(function() {
                        sessionStorage.clear();
                        localStorage.clearAll();
                        $state.go('login');
                    });
                }
            };
        }
    ]);

    authenticationModule.factory('forgottenService', ['$http',
        function($http) {
            return {
                forgotten: function(email, scope) {

                    $http.get('https://account.getinhouse.com/session/password_recover?email=' + email).success(function(response) {
                        console.log(response);
                        scope.success();
                    });
                }
            };
        }
    ]);

    authenticationModule.factory('recoverService', ['$http', '$location',
        function($http, $location) {
            return {
                recover: function(scope, password, hash, code) {

                    var timestamp = Math.round(new Date().getTime() / 1000) - 5;
                    var secret_key = "446a0ebe61b3c4cdf0766e77e63ce271";
                    var json_data = JSON.stringify({
                        'forgotten_password_code': code,
                        'user_hash': hash,
                        'password': password
                    });
                    var stringdata = "PUT /forgotten?appname=inhouse4&timestamp=" + timestamp + " " + json_data;
                    var signature = CryptoJS.HmacSHA256(stringdata, secret_key).toString();

                    var $promise = $http.put('http://login.admininhouse.com/forgotten?appname=inhouse4&timestamp=' + timestamp + '&signature=' + signature, json_data);
                    $promise.then(function(msg) {
                        var data = msg.data;
                        if (data.success === true) {
                            $location.path('login');
                        } else {
                            scope.fail();
                        }
                    });
                }
            };
        }
    ]);

    authenticationModule.factory('registerService', ['$http', 'getToken',
        function($http, token) {
            return {
                registro: function(data, scope) {
                    var datastring = {
                        'username': data.email,
                        'email': data.email,
                        'password': data.password,
                        'name': data.name,
                        'lastname': data.lastname
                    };

                    $http.post('https://account.getinhouse.com/session/register', datastring).success(function(response) {
                        if (response.user == 'duplicated') {
                            sessionStorage.duplicated = response.user;
                            scope.registrar();
                        } else if (response.user != 'duplicated') {
                            token.get_token(function(session) {
                                sessionStorage.csrf = session.csrf;
                                sessionStorage.logged = 'true';
                                scope.register_success(response);
                            });
                        }
                    }).error(function() {
                        scope.register_fail();
                    });
                }
            };
        }
    ]);

    authenticationModule.factory('httpRequestInterceptor', function() {
        return {
            request: function(config) {
                if (sessionStorage.logged == 'true' && sessionStorage.user !== '') {
                    config.headers['X-CSRF-Token'] = sessionStorage.csrf;
                }
                return config;
            }
        };
    });

    authenticationModule.factory('AuthenticationSites', ['$resource', function($resource) {
        return $resource(':user/sites/:id', {
            user: '@user',
            id: '@id'
        }, {
            'list': {
                method: 'GET',
                isArray: true
            }
        });
    }]);

})();
