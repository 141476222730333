// File: js/inhouse/controller.js

(function() {
    'use strict';

    var inhouseModule = angular.module('inhouseModule');

    inhouseModule.controller('InhouseCtrl', ['$scope', '$state', '$location', 'InhouseSites', 'dialogs', 'InhouseSocialNetworks',
        'InhousesiteNetworks', 'AuthenticationService', 'emailsService',

        function($scope, $state, $location, sites, dialogs, Networks, SiteNetworks, AuthenticationService, emails) {

            $scope.getSite = function() {
                $scope.site = sites.get({
                    'id': sessionStorage.site
                }, function(data) {

                    $scope.social_networks = Networks.list(function() {

                        angular.forEach($scope.social_networks, function(value) {
                            if ($scope.site.networks !== undefined) {
                                if ($scope.site.networks[value.network_name] !== undefined) {
                                    value.active = true;
                                } else {
                                    value.active = false;
                                }
                            } else {
                                value.active = false;
                            }
                        });
                    });

                    if ($state.current.name == 'inhouse') {
                        $state.go('inhouse.panel', {
                            'page_id': data.id_home
                        });
                    }
                });
            };

            // NOTE: Scope del site
            $scope.setSite = function(site) {
                sessionStorage.site = site.id;
                sessionStorage.home = site.id_home;

                $scope.site = sites.get({
                    'id': site.id
                }, function(data) {
                    if ($state.current.name == 'inhouse.panel' || $state.current.name == 'inhouse.contenido') {
                        $state.go('inhouse.panel', {
                            'page_id': data.id_home
                        });

                    }
                });
            };

            //NOTE: codigo para el borrado de sitios
            $scope.removeSite = function(idx, invoice) {
                if (invoice !== 'null') {

                    dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                        'windowClass': 'delete',
                        'size': 'sm'

                    }).result.then(function() {
                        var site = $scope.sites[idx];
                        sites.destroy({
                            'id': site.id
                        }).$promise.then(function() {
                            $scope.sites.splice(idx, 1);
                            sites.list(function(data) {
                                $scope.site = data[0];
                                sessionStorage.site = $scope.site.id;
                                $state.go('inhouse.panel', {
                                    'page_id': $scope.site.id_home
                                });
                            });
                        }, function() {
                            console.log('No se resolvio la petición');
                        });
                    });
                } else {
                    alert('No es posible eliminar este sitio');
                }
            };


            // NOTE: Seteamos el Focus del input para cambio de nombre
            $scope.setFocus = function() {
                $('.site_name').focus();
                $scope.name_state = 'edition';
            };

            $scope.setFocus2 = function() {
                $('.sites-titles').focus();
                $scope.name_state = 'edition';
            };

            // NOTE: Elemento que cambia los iconos para guardar y cancelar el cambio de nombre de sitio
            $scope.changingName = function() {
                $scope.name_state = 'edition';
            };

            // NOTE: Funcion que cancela la edicon del nombre del sitio y retorna los valores originales
            $scope.cancelName = function() {
                sites.get({
                    'id': $scope.site.id
                }, function(data) {
                    $scope.site.name = data.name;
                    $scope.name_state = 'default';
                });
            };

            // NOTE: Función que redirecciona nuevamente al panel de sitios
            $scope.getSites = function() {
                $state.go('sites');
            };

            // NOTE: Función que redirecciona al control de licencias
            $scope.getLicense = function() {
                $state.go('payment');
            };

            // NOTE: Funcion que guarda el nombre del sitios
            $scope.saveName = function() {
                sites.update({
                    'name': $scope.site.name,
                    'id': $scope.site.id
                }, function() {
                    $scope.name_state = 'default';
                });
            };

            $scope.saveSiteName = function(site) {
                sites.update({
                    'name': site.name,
                    'id': site.id
                }, function() {
                    $scope.name_state = 'default';
                });
            };

            // NOTE: Funciones de los menu slide

            $scope.showLeft = function() {
                var menuLeft = document.getElementById('cbp-spmenu-s1');
                classie.toggle(menuLeft, 'cbp-spmenu-open');
            };

            $scope.showRight = function() {
                var menuRight = document.getElementById('cbp-spmenu-s2');
                classie.toggle(menuRight, 'cbp-spmenu-open');
            };


            // Funciones para abrir la ventana de correos
            $scope.openEmail = function() {
                if ($scope.site.hash_invoice !== null &&
                    $scope.site.hash_invoice !== '') {
                    $(".email_container").show();
                    $(".social_container").hide();
                    $(".close").hide();
                } else {
                    $state.go('payment');
                }
            };

            $scope.closeEmail = function() {
                $(".email_container").hide();
                $(".close").show();
                $scope.mailing = false;
            };
            // Funciones para abrir la ventana de redes sociales
            $scope.openSocial = function() {
                $(".social_container").show();
                $(".email_container").hide();
                $(".close").hide();
            };

            $scope.closeSocial = function() {
                $(".social_container").hide();
                $(".close").show();
            };

            $scope.setConfs = function() {
                sites.update($scope.site);
            };

            $scope.setSocial = function(network) {

                if (network.active === false) {

                    var site_network = $scope.site.networks[network.network_name];

                    SiteNetworks.destroy({
                        'id': site_network.id,
                    }).$promise.then(function() {
                        network.active = false;
                    });
                } else {
                    SiteNetworks.create({
                        'id_social_networks': network.id,
                        'value': '',
                        'id_site': sessionStorage.site
                    }).$promise.then(function(data) {
                        $scope.site.networks[data.social_network.network_name] = data;
                        network.active = true;
                    });

                }
            };
            // Funcion para cerrar sesion
            $scope.logout = function() {
                AuthenticationService.logout();
            };

            // Funciones para el funcionamiento de correos
            $scope.user = sessionStorage.user_name;

            $scope.site = sites.get({
                'id': sessionStorage.site
            });

            $scope.add_mail = 'hide';
            $scope.limited = 'hide';

            $scope.correos = emails.list({
                    'site': sessionStorage.site
                },
                function(data) {

                    if ($scope.site.$promise !== undefined) {
                        // NOTE: Se realiza esta operacion para poder obtener el $promise de las request al sitio que se hizo en la ruta /inhuse
                        $scope.site.$promise.then(function() {
                            if ($scope.site.payment) {
                                if (data.length < $scope.site.payment.package.email_accounts) {
                                    $scope.add_mail = '';
                                } else {
                                    $scope.limited = '';
                                }
                            } else {
                                $scope.limited = '';
                            }
                        });

                    } else {
                        if ($scope.site.payment) {
                            if (data.length < $scope.site.payment.package.email_accounts) {
                                $scope.add_mail = '';
                            } else {
                                $scope.limited = '';
                            }
                        } else {
                            $scope.limited = '';
                        }
                    }
                });

            $scope.delete_account = function(index) {
                var email = $scope.correos[index];
                if (confirm('¿Esta seguro que desea borrar ' + email.email +
                        '? Esta acción no puede deshacerse') === true) {
                    emails.delete({
                            'site': sessionStorage.site,
                            'id': email.id
                        },
                        function() {
                            $scope.correos.splice(index, 1);
                        },
                        function() {
                            alert('Ocurrió un error al borrar el correo');
                        }
                    );
                }
            };

            $scope.mailForm = function(event) {
                $scope.mailing = true;
                event.stopPropagation();
            };

            $scope.cancelmail = function(event) {
                $scope.mailing = false;
                event.stopPropagation();
            };


            $scope.logout = function() {
                AuthenticationService.logout();
            };

            $scope.check_password = function() {
                if ($scope.email.password.length < 8) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.length > 32) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/\d/) == -1) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/[a-zA-Z]/) == -1) {
                    $scope.valid_password = false;
                } else if ($scope.email.password.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
                    $scope.valid_password = false;
                } else {
                    $scope.valid_password = true;
                }
            };

            $scope.match_password = function(password) {
                if ($scope.email.password != password) {
                    $scope.matched_password = false;
                } else {
                    $scope.matched_password = true;
                }
            };

            $scope.addMail = function() {
                if ($scope.valid_password === true && $scope.matched_password === true) {
                    $scope.email.site = sessionStorage.site;
                    $scope.email.id_site = sessionStorage.site;
                    $scope.email.email = $scope.email.local_email + '@' +
                        $scope.site.domain;
                    emails.register($scope.email, function(data) {
                        if (data.status != 'error') {
                            $scope.correos.push(data);
                            $scope.correo_creado = true;
                        } else {
                            alert('Ocurrió un error');
                        }
                    }, function() {
                        alert('Ocurrió un error');
                    });
                } else {
                    if ($scope.valid_password !== true) {
                        alert('La contraseña ingresada no es válida');
                    } else if ($scope.matched_password !== true) {
                        alert('Las contraseñas no coiciden');
                    }
                }
            };

            $scope.init = function() {
                $scope.mailing = false;

                if ($state.current.name != 'inhouse') {
                    $scope.getSite();
                }

                // NOTE: Creamos una variable para que los iconos de edicion sean diferentes
                $scope.name_state = 'default';

                // NOTE: Se crea la lista de sitios
                $scope.sites = sites.list(function(data) {
                    if (data.length === 0) {
                        $state.go('cuestionario');
                    } else {

                        if (sessionStorage.site === undefined) {

                            sessionStorage.site = data[0].id;

                            $scope.site = sites.get({
                                'id': sessionStorage.site
                            }, function(data) {

                                $scope.social_networks = Networks.list(function() {

                                    angular.forEach($scope.social_networks, function(value) {
                                        if ($scope.site.networks[value.network_name] !== undefined) {
                                            value.active = true;
                                        } else {
                                            value.active = false;
                                        }
                                    });
                                });

                                if ($state.current.name == 'inhouse') {
                                    $state.go('inhouse.panel', {
                                        'page_id': data.id_home
                                    });
                                }
                            });

                        } else {
                            $scope.getSite();
                        }
                    }
                });

                $(document).ready(function() {
                    $(document).click(function(event) {
                        var menuLeft = document.getElementById('cbp-spmenu-s1');
                        if ($(event.target).is('.hamburger, .hamburger *, .menu-slide-container1, .menu-slide-container1 *, .social_container, .social_container *, .inhouse, .inhouse *, .email_container, .email_container *')) {
                            return;
                        } else {
                            classie.remove(menuLeft, 'cbp-spmenu-open');
                        }
                    });
                });

                $(document).ready(function() {
                    $(document).click(function(event) {
                        var menuRight = document.getElementById('cbp-spmenu-s2');
                        if ($(event.target).is('.drop-style, .drop-style *, .menu-slide-container2, .menu-slide-container2 *')) {
                            return;
                        } else {
                            classie.remove(menuRight, 'cbp-spmenu-open');
                        }
                    });
                });

                $scope.site = sites.get({
                    'id': sessionStorage.site
                }, function() {
                    if ($scope.site.hash_invoice === null ||
                        $scope.site.hash_invoice === '') {
                        $scope.client = false;
                    } else {
                        $scope.client = true;
                    }
                });
            };

            $scope.init();

        }
    ]);
})();
