// File: js/contenido/stock/controller.js

(function() {
    'use strict';

    var contenidoModule = angular.module('contenidoModule');

    contenidoModule.controller('StockCtrl', ['$scope', '$state', '$stateParams', '$http', '$compile', 'ContenidoSections', 'ContenidoElements', 'ContenidoImages', 'dialogs', 'ContenidoStocksEdition',
        function($scope, $state, $stateParams, $http, $compile, ContenidoSections, Elements, Images, dialogs, Stocks) {

            $scope.section = ContenidoSections.get({
                id: $stateParams.stock_id
            });

            $scope.init = function() {
                // NOTE: Esta variable field_model es para poder almacenar el css de los elementos y modificarlos en tiemo real... asi se puede generar el modelo dinamicamente...
                $scope.field_models = {};
            };

            // NOTE: Corremos el init que es la funcion inicializadora
            $scope.init();

            // NOTE: esconde la barra de upload
            $('.progressBar').hide();

            $scope.backToPanel = function() {
                $state.go('inhouse.panel', {
                    'page_id': sessionStorage.active_page
                });
            };

            $http.get('/section_preview/' + sessionStorage.site + '/' + $stateParams.stock_id).success(function(data) {

                var layout_cont;

                layout_cont = '<div class="container">' + data.section + '</div>';
                $('.inhouse_section_preview_content').html($compile(data.section)($scope));

                // NOTE: Compila los estio del sitio en general
                $('.section_styles').append($compile(data.styles)($scope));

                $(".stockSort").sortable({
                    disabled: true
                });
            });

            $scope.stockSort = {
                animation: 150,
                handle: ".handle-move",
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                draggable: ".element_sortable",
                dataIdAttr: 'id',
                forceFallback: true
            };

            $scope.setStock = function(element, content) {
                $state.go('inhouse.contenido', {
                    'element_id': element,
                    'content_id': content
                });
            };

            $scope.addstock = function() {
                Elements.create({
                    "id_content": $scope.section.content.id,
                    "name": 'nuevo elemento',
                    "position": $scope.section.content.elements.length + 1
                }).$promise.then(function(element) {
                    var fields_content = {};

                    angular.forEach($scope.section.content.fields, function(field) {
                        fields_content[field.name] = $.dictionary[field.name];
                    });

                    if ($scope.section.content.image_behavior != 'none') {
                        $scope.elementImage(element.id_content, element.id);
                    }

                    element.fields = fields_content;

                    Elements.update(element).$promise.then(function() {
                        $scope.setStock(element.id, element.id_content);
                    });

                });
            };

            $scope.elementImage = function(idContent, idElement) {
                var random_image = Math.floor((Math.random() * 9) + 1);
                var api_key = $.images[random_image];
                Images.create({
                    'id_site': sessionStorage.site,
                    'id_content': idContent,
                    'element': idElement,
                    'id_element': idElement,
                    'public_id': api_key,
                    'extension': 'jpg',
                    'position': 0
                });
            };

            $scope.removeElement = function(id) {

                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {
                    Elements.remove({
                        'content': $scope.section.content.id,
                        'id': id
                    }, function() {
                        $('.element_stock_' + id).remove();
                    });
                });

            };

            $scope.selectedStocks = [];

            $scope.toggleSelection = function(id_stock) {
                var idx = $scope.selectedStocks.indexOf(id_stock);

                if (idx > -1) {
                    $scope.selectedStocks.splice(idx, 1);
                } else {
                    $scope.selectedStocks.push(id_stock);
                }

            };

            $scope.stockSelected = function(id) {
                return $.inArray(id, $scope.selectedStocks) > -1;
            };

            $scope.SelectAll = function() {
                angular.forEach($scope.section.content.elements, function(element) {
                    var idx = $scope.selectedStocks.indexOf(element.id);
                    if (idx > -1) {} else {
                        $scope.selectedStocks.push(element.id);
                    }
                });
            };

            $scope.UnselectAll = function() {
                $scope.selectedStocks = [];
            };

            $scope.RemoveAll = function() {
                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {

                    Stocks.remove({
                        'stocks': $scope.selectedStocks
                    }, function() {
                        angular.forEach($scope.selectedStocks, function(element) {
                            $('.element_stock_' + element).remove();
                        });
                    });

                });
            };

            $scope.saveStock = function() {
                // NOTE: Por el momento el guardar la unica funcion que realiza es la de guardar el orden de los elementos de stock

                var stocks_array = $(".stockSort").sortable("toArray");

                Stocks.order({
                    'content': $scope.section.content,
                    'stocks': stocks_array
                });
            };

            // NOTE: Funcion para guardar los cambios realizados al elemento
            $scope.saveElement = function() {
                // NOTE: Mandar toda la informacion en una sola llamada **no creo que sea facil :(** se van a mandar el elemento para que edite el contenido y todos los estilos pertenecientes a la seccion para que pueda ser grabados.
                var data = {};

                // NOTE: Arriba creamos una variable data en esa variable insertamos una propiedad element que es en la que metemos el elemento de la seccion
                data.element = $scope.element;

                // NOTE: Hacemos un update al edition de la seccion le mandamos como parametro el data que es el que contiene el elemento y los estilos
                $scope.element.styles = $scope.field_models;

                Stocks.update(data, function(data) {
                    // NOTE: Hacemos esto en el return para que edite el nombre del elemento en la edicion
                    $scope.element.name = data.element.name;
                });
            };
        }
    ]);
})();
