// File: js/app.js

(function() {
    'use strict';

    /* App Module */

    var inhouseApp = angular.module('inhouseApp', [
        'ngScrollbars',
        'ngAnimate',
        'ngCapsLock',
        'angularSpectrumColorpicker',
        'pascalprecht.translate',
        'ui.router',
        'angular-redactor',
        'inhouseServices',
        'inhouseExceptions',
        'cloudinary',
        'ngFileUpload',
        'LocalStorageModule',
        'slidePushMenu',
        'ui.bootstrap.tpls',
        'ui.bootstrap.modal',
        'ui.bootstrap.tabs',
        'dialogs.main',
        'ui.sortable',
        'checklist-model',
        'frapontillo.bootstrap-switch',
        'ngMaterial',
        // Modules
        'commonsModule',
        'authenticationModule',
        'cuestionarioModule',
        'inhouseModule',
        'panelModule',
        'contenidoModule',
        'configurationModule',
        'publishModule'
    ]);


    inhouseApp.config(function($stateProvider, $urlRouterProvider, $translateProvider, $httpProvider, localStorageServiceProvider, ScrollBarsProvider) {

        ScrollBarsProvider.defaults = {
            scrollButtons: {
                scrollAmount: 'auto',
                enable: true
            },
            scrollInertia: 200,
            axis: 'y',
            theme: 'dark-3',
            autoHideScrollbar: true
        };

        localStorageServiceProvider.setPrefix('INhouse');

        $httpProvider.interceptors.push('httpRequestInterceptor');

        $urlRouterProvider.otherwise('/404-error');

        $translateProvider.useStaticFilesLoader({
            prefix: 'js/languages/',
            suffix: '.json'
        });

        $translateProvider.preferredLanguage('es');

        $translateProvider.useSanitizeValueStrategy('escape');

        $stateProvider
            .state('404-error', {
                url: '/404-error',
                views: {
                    'main': {
                        templateUrl: 'partials/404-error.html'
                    }
                }
            })

        .state('/', {
            url: '/',
            views: {
                "main": {
                    templateUrl: 'partials/authentication/login.html',
                    controller: 'LoginCtrl',
                },
                "modals": {
                    templateUrl: 'partials/authentication/modals/login.html',
                    controller: 'LoginModalCtrl',
                }
            }
        })

        .state('login', {
            url: '/login',
            views: {
                "main": {
                    templateUrl: 'partials/authentication/login.html',
                    controller: 'LoginCtrl',
                },
                "modals": {
                    templateUrl: 'partials/authentication/modals/login.html',
                    controller: 'LoginModalCtrl',
                }
            }
        })

        .state('renard', {
            url: '/renard',
            views: {
                "main": {
                    templateUrl: 'partials/authentication/login.html',
                    controller: 'LoginCtrl',
                },
                "modals": {
                    templateUrl: 'partials/authentication/modals/login.html',
                    controller: 'LoginModalCtrl',
                }
            }
        })

        .state('forgotten', {
            url: '/forgotten',
            views: {
                "main": {
                    templateUrl: 'partials/authentication/forgotten.html',
                    controller: 'forgottenCtrl',
                }
            }
        })

        .state('recover', {
            url: '/forgotten/:hash/:code',
            views: {
                "main": {
                    templateUrl: 'partials/authentication/recover.html',
                    controller: 'recoverCtrl',
                }
            }
        })

        .state('cuestionario', {
            url: '/cuestionario',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/cuestionario/cuestionario.html',
                    controller: 'CuestionarioCtrl',
                }
            }
        })

        .state('inhouse', {
            url: '/inhouse',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/inhouse/inicio.html',
                    controller: 'InhouseCtrl'
                }
            }
        })

        .state('sites', {
            url: '/sitios',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/inhouse/sites-list.html',
                    controller: 'SitesListCtrl',
                }
            }
        })

        .state('payment', {
            url: '/payment',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/payment/licenseOptions.html',
                    controller: 'PaymentCtrl'
                },
            }
        })

        .state('domains', {
            url: '/domains',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/payment/domains.html',
                    controller: 'DomainsCtrl'
                },
            }
        })

        .state('publish', {
            url: '/publish',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/payment/publish.html',
                    controller: 'PublishtCtrl'
                },
            }
        })

        .state('published', {
            url: '/published',
            private: true,
            views: {
                "main": {
                    templateUrl: 'partials/published.html',
                    controller: 'PublishedtCtrl'
                },
            }
        })

        .state('inhouse.panel', {
            url: '/panel/:page_id',
            private: true,
            views: {
                "panel": {
                    templateUrl: 'partials/panel/inicio.html',
                    controller: 'PanelCtrl'
                }
            }
        })

        .state('inhouse.contenido', {
            url: '/contenido/:content_id/:element_id',
            private: true,
            views: {
                "panel": {
                    templateUrl: 'partials/contenido/single/inicio.html',
                    controller: 'ContenidoCtrl'
                }
            }
        })

        .state('inhouse.stock', {
            url: '/stock/:stock_id',
            private: true,
            views: {
                "panel": {
                    templateUrl: 'partials/contenido/stock/inicio.html',
                    controller: 'StockCtrl'
                }
            }
        })

        .state('inhouse.header', {
            url: '/header',
            private: true,
            views: {
                "panel": {
                    templateUrl: 'partials/contenido/header/inicio.html',
                    controller: 'HeaderCtrl'
                }
            }
        })

        .state('inhouse.footer', {
            url: '/footer',
            private: true,
            views: {
                "panel": {
                    templateUrl: 'partials/contenido/footer/inicio.html',
                    controller: 'FooterCtrl'
                }
            }
        });


    });

    inhouseApp.run(function($rootScope, $location, localStorageService, getToken) {

        $rootScope.$on('$stateChangeStart', function(event, toState) {
            if (localStorageService.get('is_logged')) {
                if (toState.private && sessionStorage.logged != 'true' || sessionStorage.logged != 'true') {

                    if (localStorageService.get('logged')) {
                        sessionStorage.logged = true;
                        sessionStorage.user = localStorageService.get('user');
                        sessionStorage.user_name = localStorageService.get('name');
                        getToken.get_token(function(session) {
                            sessionStorage.csrf = session.csrf;
                        });

                    } else {
                        ga('set', 'page', '/login');
                        ga('send', 'pageview');
                        localStorageService.clearAll();
                        $location.path('/login');
                    }
                } else if (toState.url == '/login') {
                    if (sessionStorage.logged == 'true') {
                        $location.path('/inhouse');
                        ga('set', 'page', '/sitios');
                        ga('send', 'pageview');
                    } else if (localStorageService.get('logged')) {

                        sessionStorage.logged = true;
                        sessionStorage.user = localStorageService.get('user');
                        sessionStorage.user_name = localStorageService.get('name');
                        getToken.get_token(function(session) {
                            sessionStorage.csrf = session.csrf;
                        });
                    } else {
                        localStorageService.clearAll();
                    }
                } else {
                    ga('set', 'page', toState.url);
                    ga('send', 'pageview');
                }
            } else {
                $location.path('/login');
                localStorageService.clearAll();
            }
        });
    });
})();
