// File: js/panel/modals.js

/* jshint loopfunc:true */
(function() {
    'use strict';
    var panelModule = angular.module('panelModule');
    // NOTE: Este es el modal que abre el controler de las configuraciones iniciales de las paginas
    panelModule.controller('pageNameCtrl', ['$scope', '$modalInstance', 'site',

        function($scope, $modalInstance, site) {

            $scope.site = site;

            $scope.accept = function() {
                $modalInstance.close($scope.page);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };
        }
    ]);

    panelModule.controller('LayoutCtrl', ['$scope', '$modalInstance', 'PanelLayouts', 'layout', 'PanelPages', 'type_layout',

        function($scope, $modalInstance, layouts, id_layout, Pages, type_layout) {

            $scope.layouts = layouts.list({
                'type': type_layout
            });

            $scope.changeLayout = function(layout) {

                Pages.update({
                    id: id_layout,
                    id_layout: layout.id
                }, function() {
                    $modalInstance.close();
                });
            };

            $scope.accept = function() {
                $modalInstance.close($scope.page);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };
        }
    ]);

    // NOTE: Este modal es el que abre las secciones para elegir el tipo de snippet que deseamos,
    panelModule.controller('pageLayoutCtrl', ['$scope', '$modalInstance', 'page', 'site', 'PanelSnnipetsContents', 'PanelPages', 'PanelContents', 'PanelElements', 'PanelImages', 'PanelSections',

        function($scope, $modalInstance, page, site, Snippets, Pages, Contents, Elements, Images, Sections) {


            $scope.page = page;
            $scope.site = site;
            $scope.section = {};

            // NOTE: Se valida si la pagina agregada es de productos o portafolio
            if ($scope.page.type == 'PRODUCTO') {
                //NOTE: Valores necesarios en caso de que la pagina sea de productos
                $scope.type = 6;
                $scope.section.name = 'Productos';
                $scope.section.type = 'products';
            }
            /* else if ($scope.page.type == 'PORTAFOLIO') {
                            // NOTE: Valores necesarios en caso de que la pagina sea de portafolio
                        }*/

            // NOTE: Obtenemos los snippets depende del tipo de pagina que se haya seleccionado
            $scope.snippets = Snippets.list({
                'type': $scope.type
            });

            // NOTE: Funcion cuando seleccionan el tipo de snippet que se desea.
            $scope.selectSnippet = function(snippet) {
                $scope.page.snippet = snippet;

                // NOTE: Se crea una pagina (menu) con los valores necesarios
                Pages.create({
                    "id_site": $scope.site.id,
                    "name": $scope.page.name,
                    "position": $scope.site.menus.length + 1,
                    "id_type_layout": 4,
                    "extern": false,
                    "is_submenu": false
                }).$promise.then(function(data) {
                    $scope.new_page = data;
                    // NOTE: Creamos un nuevo contenido para luego ingresarlo en la pagina que recien Creamos
                    Contents.create({
                        "id_site": $scope.site.id,
                        "name": $scope.section.name,
                        "behavior": "stock",
                        "type": $scope.section.type,
                        "image_behavior": "stock",
                        "fields": $scope.page.snippet.fields,
                        "stock_description": "Habla de tus " +
                            "productos o servicios, crea una galería de " +
                            "un producto o servicio y agrega una breve" +
                            " descripción."
                    }).$promise.then(function(data) {
                        $scope.defaultContent(data);
                    });
                });

            };

            $scope.defaultContent = function(content) {
                // NOTE: Recorremos con un each todos los fields que el contenido posee para poder ingresar valores por defecto
                var fields_content = {};

                angular.forEach(content.fields, function(field) {
                    fields_content[field.name] = $.dictionary[field.name];
                });

                var counter = 0;

                for (var i = 0; i < 5; i++) {
                    Elements.create({
                        "id_site": $scope.site.id,
                        "name": $scope.section.name,
                        "position": counter,
                        "id_content": content.id
                    }).$promise.then(function(element) {
                        element.id_content = content.id;
                        element.id_site = $scope.site.id;
                        element.fields = fields_content;
                        Elements.update(element).$promise.then(function() {
                            counter++;
                        });
                    });
                }

                Sections.create({
                    "name": $scope.section.name,
                    "id_site": sessionStorage.site,
                    "id_menu": $scope.new_page.id,
                    "id_content": content.id,
                    "position": 0
                }).$promise.then(function() {
                    $modalInstance.close($scope.new_page);
                });

            };

            $scope.elementImage = function(idContent, idElement) {
                var random_image = Math.floor((Math.random() * 9) + 1);
                var api_key = $.images[random_image];

                Images.create({
                    'id_site': $scope.site.id,
                    'id_content': idContent,
                    'element': idElement,
                    'id_element': idElement,
                    'public_id': api_key,
                    'extension': 'jpg',
                    'position': 0
                });
            };
        }
    ]);

    // NOTE: Modal para edicion de menus
    panelModule.controller('pagesEditionCtrl', ['$scope', '$modalInstance', 'site', 'PanelPagesEdition', 'dialogs', 'PanelPages',

        function($scope, $modalInstance, site, PagesEdition, dialogs, Pages) {

            $scope.site = site;

            $scope.update = function() {
                PagesEdition.update({
                    'pages': $scope.site.menus
                }, function() {
                    $modalInstance.close();
                });
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.pagesSort = {
                'ui-floating': false,
                'handle': ".move-cont"
            };

            $scope.removePage = function(page, $index) {
                // NOTE: El dialog es la confirmacion de si desea eliminar el elemento
                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'
                }).result.then(function() {
                    Pages.destroy({
                        id: page.id
                    }, function() {
                        $scope.site.menus.splice($index, 1);
                    });
                });
            };

            $scope.addPage = function() {
                $modalInstance.close(true);
            };
        }
    ]);

    panelModule.controller('pageSectionsCtrl', ['$scope', '$modalInstance', 'site', 'page', 'PanelSnnipets', 'PanelContents', 'PanelElements', 'PanelImages', 'PanelSections',
        function($scope, $modalInstance, site, page, Snippets, Contents, Elements, Images, Sections) {
            $scope.site = site;
            $scope.page = page;

            $scope.sectionsTypes = [{
                    name: 'TEXTO',
                    id_type: '7',
                    type: 'text'
                }, {
                    name: 'IMAGEN',
                    id_type: '4',
                    type: 'images'
                }, {
                    name: 'CONTACTO',
                    id_type: '10',
                    type: 'contact'
                }, {
                    name: 'VIDEO',
                    id_type: '8',
                    type: 'videos'
                },
                /* {
                    name: 'AUDIO',
                    id_type: '1',
                    type: 'audios'
                }, {
                    name: 'DOCUMENTO',
                    id_type: '2',
                    type: 'documents'
                },*/
                {
                    name: 'BANNER',
                    id_type: '9',
                    type: 'banner'
                }, {
                    name: 'MAPA',
                    id_type: '11',
                    type: 'embed'
                },
                /*{
                                               name: 'TESTIMONIO',
                                               id_type: '0',
                                               type: ''
                                           }, {
                                               name: 'EVENTO',
                                               id_type: '3',
                                               type: 'events'
                                           }, {
                                               name: 'ENLACE',
                                               id_type: '5',
                                               type: 'links'
                                           },*/
                {
                    name: 'PRODUCTOS',
                    id_type: '6',
                    type: 'products'
                }
                /*,{
                                name: 'FORMULARIO',
                                id_type: '10',
                                type: 'contact'
                            }, {
                                name: 'ICONOS SOCIALES',
                                id_type: '0',
                                type: ''
                            }, {
                                name: 'BOTON',
                                id_type: '0',
                                type: ''
                            }, {
                                name: 'LINEA',
                                id_type: '0',
                                type: ''
                            }, {
                                name: 'NEWSLETTER',
                                id_type: '0',
                                type: ''
                            }, {
                                name: 'BUSCADOR',
                                id_type: '0',
                                type: ''
                            }*/
            ];

            $scope.setType = function(type) {
                $scope.type = type;
                $scope.snippets = Snippets.list({
                    'type': type.id_type
                });
            };

            $scope.config_scroll = {
                autoHideScrollbar: false,
                setHeight: '100%',
                setTop: '-100px',
                theme: 'dark'
            };

            $scope.CreateSection = function(snippet) {
                $scope.snippet = snippet;
                Contents.create({
                    "name": $scope.type.name,
                    "behavior": $scope.snippet.behavior,
                    "type": $scope.type.type,
                    "image_behavior": $scope.snippet.image_behavior,
                    "fields": $scope.snippet.fields
                }, function(content) {
                    if (content.behavior == 'single') {
                        $scope.createSingle(content);
                    } else {
                        $scope.createStocks(content);
                    }
                });
            };

            $scope.createSingle = function(content) {
                var fields_content = {};

                angular.forEach(content.fields, function(field) {
                    if (field.type == 'image') {
                        var random_image = Math.floor((Math.random() * 9) + 1);
                        fields_content[field.name] = 'https://res.cloudinary.com/inhouse/image/upload/c_limit,q_100/w_' + $scope.snippet.img_width + ',h_' + $scope.snippet.img_height + ',c_fill/' + $.images[random_image] + '.jpg';
                    } else {
                        fields_content[field.name] = $.dictionary[field.name];
                    }
                });

                Elements.create({
                    'id_site': sessionStorage.site,
                    'name': $scope.type.name,
                    'position': 0,
                    'id_content': content.id
                }).$promise.then(function(element) {

                    if (content.image_behavior == 'stock') {
                        $scope.elementImage(content.id, element.id);
                    }

                    var i = 0;

                    if ($scope.type.name == 'banner') {
                        for (i = 0; i < 1; i++) {
                            $scope.elementImage(content.id, element.id);
                        }
                    }

                    element.id_content = content.id;
                    element.id_site = sessionStorage.site;
                    element.fields = fields_content;

                    Elements.update(element).$promise.then(function() {
                        $scope.addSection(content.id);
                    });

                });
            };

            $scope.createStocks = function(content) {
                // NOTE: Recorremos con un each todos los fields que el contenido posee para poder ingresar valores por defecto
                var fields_content = {};

                angular.forEach(content.fields, function(field) {
                    if (field.type == 'image') {
                        var random_image = Math.floor((Math.random() * 9) + 1);
                        fields_content[field.name] = 'https://res.cloudinary.com/inhouse/image/upload/c_limit,q_100/w_' + $scope.snippet.img_width + ',h_' + $scope.snippet.img_height + ',c_fill/' + $.images[random_image] + '.jpg';
                    } else {
                        fields_content[field.name] = $.dictionary[field.name];
                    }
                });

                var counter = 0;

                for (var i = 0; i < 5; i++) {
                    Elements.create({
                        "id_site": $scope.site.id,
                        "name": $scope.type.name,
                        "position": counter,
                        "id_content": content.id
                    }).$promise.then(function(element) {
                        if (content.image_behavior == 'stock') {
                            $scope.elementImage(content.id, element.id);
                        }
                        element.id_content = content.id;
                        element.id_site = $scope.site.id;
                        element.fields = fields_content;
                        Elements.update(element).$promise.then(function() {
                            counter++;
                        });
                    });
                }
                $scope.addSection(content.id);
            };

            $scope.elementImage = function(idContent, idElement) {
                var random_image = Math.floor((Math.random() * 9) + 1);
                var api_key = $.images[random_image];

                Images.create({
                    'id_site': sessionStorage.site,
                    'id_content': idContent,
                    'element': idElement,
                    'id_element': idElement,
                    'public_id': api_key,
                    'extension': 'jpg',
                    'position': 0
                });
            };

            $scope.addSection = function(content) {
                Sections.create({
                    "name": $scope.type.name,
                    "id_site": sessionStorage.site,
                    "id_menu": $scope.page,
                    "id_content": content,
                    "position": 0,
                    "id_snippet": $scope.snippet.id
                }).$promise.then(function(data) {
                    $modalInstance.close(data);
                });
            };

        }
    ]);

    panelModule.controller('SnippetCtrl', ['$scope', 'PanelSections', 'PanelSnnipets', 'id_section', 'id_page', '$modalInstance', 'PanelContents',
        function($scope, Sections, Snippets, id_section, id_page, $modalInstance, Contents) {

            Sections.get({
                'menu': id_page,
                'id': id_section
            }, function(section) {
                $scope.section = section;
                $scope.snippets = Snippets.list({
                    'type': section.content.type.id
                });
            });

            $scope.fields_counter = 0;

            $scope.changeSnippet = function(snippet) {

                angular.forEach(snippet.fields, function(value) {

                    var result = $.grep($scope.section.content.fields, function(e) {
                        return e.name == value.name;
                    });

                    if (result.length === 0) {

                        var new_field = {
                            type: value.type,
                            name: value.name
                        };

                        $scope.section.content.fields.push(new_field);

                        $scope.setUpdate(snippet);
                    } else {
                        $scope.setUpdate(snippet);
                    }

                });
            };

            $scope.setUpdate = function(snippet) {

                $scope.fields_counter = $scope.fields_counter + 1;

                if ($scope.fields_counter < snippet.fields.length) {

                } else {
                    Contents.update($scope.section.content, function() {
                        Sections.update({
                            'id_menu': id_page,
                            'id': id_section,
                            'id_snippet': snippet.id,
                            'fields': snippet.fields
                        }).$promise.then(function() {
                            $modalInstance.close();
                        });
                    });
                }

            };

        }
    ]);

})();
