(function() {
    'use strict';

    /* Controllers */

    var publishModule = angular.module('publishModule');


    publishModule.controller('PublishtCtrl', ['$scope', '$location',
        'CuestionarioSites', 'AuthenticationService', '$state',
        function($scope, $location, sites, AuthenticationService, $state) {

            $scope.site = sites.get({
                'id': sessionStorage.site
            }, function() {
                if ($scope.site.published === true) {
                    $location.path('/published');
                }
            });

            $scope.user = sessionStorage.user_name;

            $scope.hash_user = sessionStorage.user;

            $scope.logout = function() {
                AuthenticationService.logout();
            };

            $scope.cancelPublish = function() {
                $state.go('payment');
            };

            $scope.publishSite = function() {
                $scope.site.published = true;
                sites.update($scope.site, function() {
                    $location.path('/published');
                });
            };

        }
    ]);
})();
