// File: js/contenido/stock/controller.js

(function() {
    'use strict';

    var contenidoModule = angular.module('contenidoModule');

    contenidoModule.controller('HeaderCtrl', ['$scope', '$http', '$compile', '$state', 'Upload', 'ContenidoSites', 'ContenidoHeaderFooterEdition', 'dialogs',

        function($scope, $http, $compile, $state, $upload, Sites, Edition, dialogs) {
            $scope.renderMenu = function() {
                $http.get('/header_preview/' + sessionStorage.site).success(function(data) {

                    $('.inhouse_section_preview_content').html($compile(data.header)($scope));
                    // NOTE: Compila los estio del sitio en general
                    $('.section_styles').append($compile(data.styles)($scope));

                });
            };

            $scope.init = function() {
                // NOTE: Se valida si el $promise de el sitio es indefinido
                if ($scope.site.$promise !== undefined) {
                    // NOTE: Se realiza esta operacion para poder obtener el $promise de las request al sitio que se hizo en la ruta /inhouse
                    $scope.site.$promise.then(function(site) {
                        $scope.site = site;
                        $scope.pages = site.menus;
                    });
                }

                $scope.renderMenu();

                //NOTE: Variable para almacenar el css de los las paginas de los menus
                $scope.page_models = {};
            };

            //NOTE: Funcion para que inicie el loader escondido.
            setTimeout(function() {
                $('.progressLoad').hide();
            }, 3000);


            $scope.init();

            // NOTE: Funcion para regresar al panel desde el editor
            $scope.backToPanel = function() {
                if (sessionStorage.active_page === undefined) {
                    sessionStorage.active_page = $scope.site.id_home;
                }

                $state.go('inhouse.panel', {
                    'page_id': sessionStorage.active_page
                });
            };

            //NOTE Modify the look and fill of the dropzone when files are being dragged over it
            $scope.dragOverClass = function($event) {
                var items = $event.dataTransfer.items;
                var hasFile = false;
                if (items !== null) {
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].kind == 'file') {
                            hasFile = true;
                            break;
                        }
                    }
                } else {
                    hasFile = true;
                }
                return hasFile ? "dragover" : "dragover-err";
            };

            // NOTE: Esta funcion es para todas las propiedades que tengan que ver con la edición inmediata, reciben el nombre de la propiedad, y la pagina al que se ingresara.
            $scope.modProp = function(prop, page) {
                // NOTE: Se cambia el css en tiemo real del elemento.
                $('.' + page).css(prop, $scope.page_models[page][prop]);
            };


            $scope.uploadFiles = function(files) {
                // NOTE: Obtenemos el primer archivo del que nos envian el arreglo
                $('.uploadButton').hide();
                $('.progressLoad').show();
                var file = files[0];

                if (file && !file.$error) {
                    $scope.upload = $upload.upload({
                        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
                        url: "https://api.cloudinary.com/v1_1/" +
                            $.cloudinary.config().cloud_name + "/image/upload",
                        data: {
                            upload_preset: $.cloudinary.config().upload_preset,
                            folder: 'v6_site_' + sessionStorage.site,
                            context: 'photo=' + file.name
                        },
                        file: file
                    }).progress(function(e) {
                        var percent = Math.round((e.loaded * 100.0) / e.total);

                        $scope.determinateValueCircle = percent;

                    }).success(function(data) {

                        var url_image = 'https://res.cloudinary.com/' + $.cloudinary.config().cloud_name + '/image/upload/c_limit,q_100/w_160,h_80,c_fit/' + data.public_id + '.' + data.format;

                        Sites.update({
                            id: sessionStorage.site,
                            logo: url_image,
                            public_id: data.public_id
                        });

                        var img_html = '<div class="img_logo"><div class="img-opts-header"><img src="' + url_image + '" class="header_logo img-responsive"><div class="remove" ng-click="removeLogo()"><span class="glyphicon icon-basurero"></span></div><div class="edition"><span class="glyphicon icon-lapiz"></span></div></div></div>';

                        $('.img_logo').html($compile(img_html)($scope));

                    }).error(function(data) {
                        file.result = data;
                    });
                }
            };

            $scope.removeLogo = function() {
                dialogs.create('partials/dialogs/confirm.html', 'ConfirmCtrl', {}, {
                    'windowClass': 'delete',
                    'size': 'sm'

                }).result.then(function() {
                    Sites.update({
                        id: sessionStorage.site,
                        logo: '',
                        public_id: ''
                    }, function() {

                        var img_html = '<div class="img_logo"><div class="img-not-opt img-upload" ngf-drop="uploadFiles($files)" ngf-drag-over-class="dragOverClass($event)" ng-model="files" ng-multiple="false"><div class="add" ngf-select="uploadFiles($files)" resetOnClick="true"><div class="progressLoad"><md-progress-circular md-mode="determinate" value="{{determinateValueCircle}}"></md-progress-circular></div><div class="uploadButton"><span class="icon-agregar2"></span><p>Agregar Imagen</p></div></div><img src="http://dummyimage.com/160x80/"></div></div>';

                        $('.img_logo').html($compile(img_html)($scope));
                        $('.progressLoad').delay(3000).hide();

                    });
                });
            };

            setTimeout(function() {
                    $('.menu').children().each(function() {
                        console.log(this.value);
                    });
                },
                3000);

            // NOTE: Funcion para guardar los cambios realizados al elemento
            $scope.saveElement = function() {
                // NOTE: Mandar toda la informacion en una sola llamada **no creo que sea facil :(** se van a mandar el elemento para que edite el contenido y todos los estilos pertenecientes a la seccion para que pueda ser grabados.
                var data = {};

                // NOTE: Arriba creamos una variable data en esa variable insertamos una propiedad element que es en la que metemos el elemento de la seccion
                data.element = $scope.element;

                // NOTE: Hacemos un update al edition de la seccion le mandamos como parametro el data que es el que contiene el elemento y los estilos
                $scope.element.styles = $scope.page_models;

                // Mandamos los datos al backend para que se guarden a la base de datos
                Edition.update(data);

            };

        }
    ]);
})();
