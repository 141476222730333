(function() {
    'use strict';

    /* Common Functions*/

    var inhouseServices = angular.module('inhouseServices', []);

    inhouseServices.factory('SessionService', [function() {
        return {
            set: function(key, value) {
                return sessionStorage.setItem(key, value);
            },
            get: function(key) {
                return sessionStorage.getItem(key);
            },
            destroy: function() {
                return sessionStorage.clear();
            }
        };
    }]);

    inhouseServices.factory('getToken', ['$resource', function($resource) {
        return $resource('get_token', {}, {
            'get_token': {
                method: 'POST'
            }
        });
    }]);

    inhouseServices.factory('removeToken', ['$resource', function($resource) {
        return $resource('remove_token', {}, {
            'remove_token': {
                method: 'POST'
            }
        });
    }]);

})();
